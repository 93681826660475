import { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, createNewTheme, maskCpfCnpj, maskHoras, maskPhone, maskReais, parsePhones } from '../../functions/utils';
import { AccordionDetails, Alert, Avatar, Button, Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Add, AddCircleOutline, CardMembershipOutlined, CreditCard, DeleteOutline, EmailOutlined, KeyboardArrowRight, PermIdentityOutlined, PersonOutlineOutlined, Phone, PhoneOutlined, PixOutlined, RemoveCircleOutline, UploadOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Accordion, AccordionSummary, MaterialUISwitch } from '../../components/utils';

function Configuracoes({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);
  const container = windows !== undefined ? () => windows().document.body : undefined;

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFileImage, setSelectedFileImage] = useState(null);
  const [abaConfiguracao, setAbaConfiguracao] = useState(0);
  const [abaConta, setAbaConta] = useState(0);
  const [abaPagamentos, setAbaPagamento] = useState(0);
  const [email, setEmail] = useState("");
  const [novoEmail, setNovoEmail] = useState("");
  const [confirmarNovoEmail, setConfirmarNovoEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [showSenha, setShowSenha] = useState(false);
  const [showNovaSenha, setShowNovaSenha] = useState(false);
  const [showConfirmaNovaSenha, setShowConfirmaNovaSenha] = useState(false);
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [hasAddress, setHasAddress] = useState(false);
  const [semLogradouro, setSemLogradouro] = useState(false);

  const [chavePix, setChave] = useState("");
  const [link, setLink] = useState("");

  const [nomeLoja, setNomeLoja] = useState("");
  const [sobreNos, setSobreNos] = useState("");
  const [telefones, setTelefones] = useState(['']);

  const [notificarEstoqueMinimo, setNotificarEstoqueMinimo] = useState(false);
  const [todosDias, setTodosDias] = useState(false);
  const [dinheiro, setDefinirDinheiro] = useState(false);
  const [definirManualmente, setDefinirManualmente] = useState(false);
  const [valorCombinar, setValorCombinar] = useState(false);

  const [expandedCredito, setExpandedCredito] = useState(false);
  const [expandedVales, setExpandedVales] = useState(false);
  const [expandedPix, setExpandedPix] = useState(false);
  const [expandedOutros, setExpandedOutros] = useState(false);

  const [selecionarTodosCartoes, setSelecionarTodosCartoes] = useState(false);
  const [selecionarTodosVales, setSelecionarTodosVales] = useState(false);

  const [todosDiasInicio, setTodosDiasInicio] = useState("");
  const [todosDiasFim, setTodosDiasFim] = useState("");

  const [creditos, setCreditos] = useState("");
  const [vales, setVales] = useState("");

  const [tipoChave, setTipoChave] = useState("");

  const navigate = useNavigate();

  const [timeFields, setTimeFields] = useState([{ start: '', end: '', dates: '' }]);

  const [taxas, setTaxas] = useState([{ raio: '', valor: '' }]);

  const handleTipoChave = (tipo) => {
    setTipoChave(tipo)
  };

  const handleExpanded = (tipo) => () => {
    switch (tipo) {
      case "credito":
        setExpandedCredito(!expandedCredito);
        return;
      case "vales":
        setExpandedVales(!expandedVales);
        return;
      case "pix":
        setExpandedPix(!expandedPix);
        return;
      case "outros":
        setExpandedOutros(!expandedOutros);
        return;
      default:
        return;
    }
  };

  const handleAddCredito = (text) => {
    setSelecionarTodosCartoes(false)
    if (!creditos) {
      setCreditos(creditos + ',')
    }
    if (creditos.includes(text)) {
      setCreditos(creditos.replace(text + (text.includes(",") ? "" : ','), ''));
    } else {
      setCreditos(creditos + text + ',');
    }
  };


  const handleSelecionarTodosCartoes = (event) => {
    setCreditos(event.target.checked ? "mastercard,banricompras,sorocred,americanexpress,aura,credicard,hipercard,elo,verdecard,pan,dinnersclub,visa," : "")
    setSelecionarTodosCartoes(event.target.checked)
  };

  const handleAddVales = (text) => {
    setSelecionarTodosVales(false)
    if (!vales) {
      setVales(vales + ',')
    }
    if (vales.includes(text)) {
      setVales(vales.replace(text + (text.includes(",") ? "" : ','), ''));
    } else {
      setVales(vales + text + ',');
    }
  };


  const handleSelecionarTodosVales = (event) => {
    setVales(event.target.checked ? "alelo,sodexo,ticket,vrbeneficios,flash,greencard,vb,ifoodalimentacao," : "")
    setSelecionarTodosVales(event.target.checked)
  };

  const handleTaxaChange = (index, field, event) => {
    const newTaxas = [...taxas];
    const { value } = event.target;

    if (field === 'valor') {
      newTaxas[index][field] = maskReais(value);
    } else {
      newTaxas[index][field] = value;
    }

    setTaxas(newTaxas);
  };

  const addTaxaField = () => {
    setTaxas([...taxas, { raio: '', valor: '' }]);
  };

  const removeTaxaField = (index) => {
    const newTaxas = taxas.filter((_, i) => i !== index);
    setTaxas(newTaxas);
  };

  const handleChangeCombinar = (event) => {
    setValorCombinar(event.target.checked);
  };

  const handleChangeNotificarEstoqueMinimo = (event) => {
    setNotificarEstoqueMinimo(event.target.checked);
    Cookies.set('eMA', event.target.checked, { expires: 7 });
  };

  const handleChangeTodosDias = (event) => {
    setTodosDias(event.target.checked);
    event.target.checked ? setDefinirManualmente(false) : console.log("")
  };

  const handleChangeDinheiro = (event) => {
    setDefinirDinheiro(event.target.checked);
  };

  const handleDefinirManualmente = (event) => {
    setDefinirManualmente(event.target.checked);
    event.target.checked ? setTodosDias(false) : console.log("")
  };

  const handleAddField = () => {
    setTimeFields([...timeFields, { start: '', end: '', dates: '' }]);
  };

  const handleRemoveField = (index) => {
    const newTimeFields = timeFields.filter((_, i) => i !== index);
    setTimeFields(newTimeFields);
  };

  const addLetra = (dates, letra) => {
    for (const timeField of timeFields) {
      if (timeField.dates && timeField.dates.includes(letra) && timeField.dates !== dates) {
        return dates
      }
    }
    if (!dates) {
      return letra + ','
    }
    if (dates.includes(letra)) {
      return dates.replace(letra + (letra.includes(",") ? "" : ','), '');
    } else {
      return dates + letra + ',';
    }
  };

  const handleDiaChange = (index, field, value) => {
    const newTimeFields = timeFields.map((timeField, i) => {
      if (i === index) {
        return { ...timeField, ['dates']: addLetra(timeField.dates, field) };
      }
      return timeField;
    });
    setTimeFields(newTimeFields);
  };

  const handleTimeChange = (index, field, value) => {
    const newTimeFields = timeFields.map((timeField, i) => {
      if (i === index) {
        return { ...timeField, [field]: maskHoras(value) };
      }
      return timeField;
    });
    setTimeFields(newTimeFields);
  };

  const handleTodosDiasInicio = (event) => {
    setTodosDiasInicio(maskHoras(event.target.value))
  };

  const handleTodosDiasFim = (event) => {
    setTodosDiasFim(maskHoras(event.target.value))
  };

  const handleTelefoneChange = (index, event) => {
    const newTelefones = [...telefones];
    newTelefones[index] = maskPhone(event.target.value);
    setTelefones(newTelefones);
  };

  const addTelefone = () => {
    setTelefones([...telefones, '']);
  };

  const removeTelefone = (index) => {
    const newTelefones = [...telefones];
    if (newTelefones.length > 1) {
      newTelefones.splice(index, 1);
      setTelefones(newTelefones);
    }
  };

  const handleChave = (event) => {
    let chave = event.target.value
    if (tipoChave === "telefone") {
      chave = maskPhone(event.target.value)
    }
    if (tipoChave === "cpf") {
      chave = maskCpfCnpj(event.target.value)
    }
    setChave(chave);
  };

  const handleLink = (event) => {
    setLink(event.target.checked);
  };

  const handleNomeLoja = (event) => {
    setNomeLoja(event.target.value);
  };

  const handleSobreNos = (event) => {
    setSobreNos(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleNovoEmail = (event) => {
    setNovoEmail(event.target.value);
  };

  const handleConfirmarNovoEmail = (event) => {
    setConfirmarNovoEmail(event.target.value);
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
  };

  const handleNovaSenha = (event) => {
    setNovaSenha(event.target.value);
  };

  const handleConfirmarNovaSenha = (event) => {
    setConfirmarNovaSenha(event.target.value);
  };

  const isNumeric = (str) => /^\d+$/.test(str);

  const handleContinuarCep = (cep) => {

    if (!isNumeric(cep)) {
      update("", `${Date.now()}___error___Digite apenas números para validar o cep.`)
      return
    }

    axios.get('https://viacep.com.br/ws/' + cep + '/json')
      .then(response => {
        response = response.data
        if (!response) {
          update("", `${Date.now()}___error___Por favor, preencha todos os campos corretamente para prosseguir.`)
          setHasAddress(false)
          return
        }
        if (response.erro) {
          update("", `${Date.now()}___error___Não foi possivel encontrar o CEP, por favor verifique sua conexão com a internet.`)
          setHasAddress(false)
          return
        }
        setHasAddress(true)
        setEndereco(response.logradouro)
        setBairro(response.bairro)
        setCidade(response.localidade)
        setEstado(response.uf)
        response.logradouro ? setSemLogradouro(false) : setSemLogradouro(true)
        // handleCloseLoading();
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleCep = (event) => {
    setCep(event.target.value);
    if (event.target.value) {
      const cep = event.target.value.replaceAll("-", "").replaceAll(" ", "").replaceAll(".", "")
      if (cep.length === 8) {
        handleContinuarCep(cep)
      }
    }
  };

  const handleEndereco = (event) => {
    setEndereco(event.target.value);
  };

  const handleNumero = (event) => {
    setNumero(event.target.value);
  };

  const handleComplemento = (event) => {
    setComplemento(event.target.value);
  };

  const handleEstado = (event) => {
    setEstado(event.target.value);
  };

  const handleCidade = (event) => {
    setCidade(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setAbaConfiguracao(newValue);
  };

  const handleContaChange = (event, newValue) => {
    setAbaConta(newValue);
  };

  const handlePagamentosChange = (event, newValue) => {
    setAbaPagamento(newValue);
  };

  const handleLogout = () => {
    Cookies.remove('dsEst');
    Cookies.remove('emEst');
    Cookies.remove('endEst');
    Cookies.remove('fMsP');
    Cookies.remove('hREst');
    Cookies.remove('iEst');
    Cookies.remove('nmEst');
    Cookies.remove('txEst');
    Cookies.remove('tEst');
    Cookies.remove('tsEst');
    Cookies.remove('UBs');
    Cookies.remove('FTs');
    Cookies.remove('isLogged');
    Cookies.remove('isStore');
    navigate("/estabelecimento/login", { replace: true, state: { page: "home" } });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;
      const validFileTypes = ['image/png', 'image/jpeg'];

      if (!validFileTypes.includes(fileType)) {
        update("", `${Date.now()}___error___Somente arquivos PNG ou JPG são permitidos.`)
        setSelectedImage(null);
        setSelectedFileImage(null);
        return;
      }

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        update("", `${Date.now()}___error___O arquivo deve ter no máximo 5MB.`)
        setSelectedImage(null);
        setSelectedFileImage(null);
        return;
      }

      const reader = new FileReader();
      setSelectedFileImage(file)
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadFile = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setSelectedImage(null)
    setSelectedFileImage(null);
  };

  const handleSalvarInfoPerfil = async () => {
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-dados-estabelecimento.php`;
    const formData = new FormData();
    selectedFileImage ? formData.append('logo', selectedFileImage) : console.log("");
    formData.append('idestabelecimentos', idestabelecimentos);
    formData.append('nome', nomeLoja);
    formData.append('descricao', sobreNos);
    try {
      const response = await axios.post(post_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data)
      if (response.data) {
        if (response.data.upload === "tamanho") {
          update("", `${Date.now()}___error___A imagem de perfil selecionada é maior que 5MB.`)
          return
        }
        if (response.data.upload === "formato") {
          update("", `${Date.now()}___error___O formato da imagem de perfil selecionada não é suportado.`)
          return
        }
        if (response.data.upload === "tipo") {
          update("", `${Date.now()}___error___O tipo da imagem de perfil selecionada não é suportado.`)
          return
        }
        if (response.data.upload === "ftp") {
          update("", `${Date.now()}___error___Ocorreu um erro ao atualizar a imagem de perfil selecionada.`)
          return
        }
        if (response.data.upload === "falha") {
          update("", `${Date.now()}___error___Ocorreu uma falha ao atualizar a imagem de perfil selecionada.`)
          return
        }
        if (response.data.resposta === "sucesso atualizar") {
          Cookies.set('dsEst', sobreNos, { expires: 7 });
          Cookies.set('nmEst', nomeLoja, { expires: 7 });
          let telefonesString = telefones.join(',')
          telefonesString = telefonesString.replace(/,+$/, '');
          if (telefonesString < 14) {
            update("", `${Date.now()}___error___Preencha pelo menos um telefone válido.`)
            return
          }

          updateTelefones(telefonesString)
          return
        }
      }
      update("", `${Date.now()}___error___Não foi possivel alterar seus dados. Por favor verifique sua conexão e tente novamente.`)
    } catch (error) {
      update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
    }
  };

  const updateTelefones = (tels) => {
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-telefones-estabelecimento.php`;
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      telefones: tels,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso atualizar") {
            Cookies.set('tEst', tels, { expires: 7 });
            update("", `${Date.now()}___success___Suas informações de perfil foram atualizadas com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel alterar seus telefones. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  }

  const handleSalvarEmail = () => {
    if (!email) {
      update("", `${Date.now()}___error___Por favor insira o e-mail atual.`)
      return
    }
    if (!novoEmail) {
      update("", `${Date.now()}___error___Por favor insira o novo e-mail.`)
      return
    }
    if (!confirmarNovoEmail) {
      update("", `${Date.now()}___error___Por favor insira novamente o novo e-mail.`)
      return
    }
    if (novoEmail !== confirmarNovoEmail) {
      update("", `${Date.now()}___error___Os e-mails precisam estar identicos para avançar.`)
      return
    }

  };

  const handleSalvarSenha = () => {
    if (!senha) {
      update("", `${Date.now()}___error___Por favor insira a senha atual.`)
      return
    }
    if (!novaSenha) {
      update("", `${Date.now()}___error___Por favor insira a nova senha.`)
      return
    }
    if (!confirmarNovaSenha) {
      update("", `${Date.now()}___error___Por favor insira novamente a nova senha.`)
      return
    }
    if (novaSenha !== confirmarNovaSenha) {
      update("", `${Date.now()}___error___As senhas precisam estar identicas para avançar.`)
      return
    }

    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-senha-estabelecimento.php`;
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      senha: senha,
      newSenha: novaSenha
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "senha invalida") {
            update("", `${Date.now()}___error___A senha atual está inválida.`)
            return
          }
          if (response.resposta === "senha curta") {
            update("", `${Date.now()}___error___Sua senha está muito curta, defina uma senha com pelo menos 7 caracteres.`)
            return
          }
          if (response.resposta === "sucesso atualizar") {
            update("", `${Date.now()}___success___Sua senha foi alterada com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel alterar sua senha. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleSalvarEndereco = () => {

    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-endereco-estabelecimento.php`;
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      rua: endereco,
      numero: `${numero}${complemento ? ` ${complemento}` : ""}`,
      bairro: bairro,
      cidade: cidade,
      uf: estado,
      cep: cep
    });
    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso atualizar") {
            Cookies.set('endEst', `${endereco}, ${`${numero}${complemento ? ` ${complemento}` : ""}`}, ${bairro}, ${cidade}, ${estado}, ${cep}`, { expires: 7 });

            Cookies.get("endEst")
            update("", `${Date.now()}___success___Seu endereço foi alterado com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel alterar seu endereço. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  useEffect(() => {
    isMobile ? setAbaConta(-1) : setAbaConta(0)
    setEmail(Cookies.get("emEst"))
    setNomeLoja(Cookies.get("nmEst"))
    setSobreNos(Cookies.get("dsEst"))

    Cookies.get('eMA') ? setNotificarEstoqueMinimo(Cookies.get('eMA') === "true") : console.log("-")

    if (Cookies.get("tEst")) {
      const arrPhones = parsePhones(Cookies.get("tEst"));
      setTelefones(arrPhones)
    }

    if (Cookies.get("endEst")) {
      let arrEnd = Cookies.get("endEst").split("___")
      arrEnd = arrEnd[0].split(", ")
      setEndereco(arrEnd[0])
      if (isNumeric(arrEnd[1])) {
        setNumero(arrEnd[1])
      } else {
        const parts = arrEnd[1].split(" ");
        const complemento = parts.slice(1).join(" ");
        setComplemento(complemento)
        setNumero(parts[0])
      }
      setBairro(arrEnd[2])
      setCidade(arrEnd[3])
      setEstado(arrEnd[4])
      setCep(arrEnd[5])
      setHasAddress(true)
    }

    const horarios = Cookies.get("hREst");

    const dias = ["S,", "T,", "Q,", "QI,", "SX,", "SB,", "D,", "FR,"];
    const horarioArray = horarios.split(',');

    const groupedHorarios = horarioArray.reduce((acc, horario, index) => {
      if (!acc[horario]) {
        acc[horario] = [];
      }
      acc[horario].push(dias[index]);
      return acc;
    }, {});

    const timeFields = Object.entries(groupedHorarios).map(([horario, dias]) => {
      let [start, end] = horario.split(' às ');

      if (start === "Fechado") start = "";
      if (end === "Fechado") end = "";

      return {
        start,
        end,
        dates: dias.join('')
      };
    });

    (timeFields && timeFields.length === 1) ? setTodosDias(true) : setDefinirManualmente(true)
    if (timeFields && timeFields.length === 1) {
      setTodosDiasInicio(timeFields[0].start)
      setTodosDiasFim(timeFields[0].end)
    }
    setTimeFields(timeFields);

    const arrFormas = Cookies.get("fMsP").split("--");
    setCreditos(arrFormas[1] ? `${arrFormas[1]},` : "")
    setVales(arrFormas[2] ? `${arrFormas[2]},` : "")
    setChave(arrFormas[3])
    if (arrFormas[3]) {
      if (arrFormas[3].includes("(") && arrFormas[3].includes(")") && arrFormas[3].includes("-")) {
        setTipoChave("telefone")
      } else if ((arrFormas[3].includes(".") && arrFormas[3].includes("-")) || (arrFormas[3].includes(".") && arrFormas[3].includes("-") && arrFormas[3].includes("/"))) {
        setTipoChave("cpf")
      } else {
        setTipoChave("email")
      }
    }
    setDefinirDinheiro(arrFormas[4] ? true : false)
    setLink(arrFormas[5] ? true : false)
    let taxasEst = Cookies.get("txEst");
    if (taxasEst.includes("CB")) {
      taxasEst = taxasEst.replaceAll("CB__", "")
      setValorCombinar(true)
    }

    setTaxas(formatStringToTaxas(taxasEst));

  }, []);

  const formatStringToTaxas = (str) => {
    const parts = str.split('--');
    const formattedTaxas = [];

    for (let i = 0; i < parts.length; i += 2) {
      formattedTaxas.push({
        raio: parts[i],
        valor: parts[i + 1]
      });
    }

    return formattedTaxas;
  };

  const handleShowSenha = () => {
    setShowSenha(!showSenha)
  }

  const handleShowNovaSenha = () => {
    setShowNovaSenha(!showNovaSenha)
  }

  const handleShowConfirmaNovaSenha = () => {
    setShowConfirmaNovaSenha(!showConfirmaNovaSenha)
  }

  const handleSalvarHorarios = async () => {
    let arrHorarios = new Array(8).fill("Fechado");
    if (todosDias) {
      if (todosDiasInicio.length > 4 && todosDiasFim.length > 4) {
        arrHorarios = new Array(8).fill(`${todosDiasInicio} às ${todosDiasFim}`);
      }
    } else {
      const diaIndice = {
        "D,": 6,  // domingo
        "S,": 0,  // segunda
        "T,": 1,  // terça
        "Q,": 2,  // quarta
        "QI,": 3, // quinta
        "SX,": 4, // sexta
        "SB,": 5, // sábado
        "FR,": 7  // feriados
      };

      for (const timeField of timeFields) {
        if (timeField.dates) {
          const horario = (timeField.start.length < 5 || timeField.end.length < 5) ? "Fechado" : `${timeField.start} às ${timeField.end}`;
          for (const dia in diaIndice) {
            if (timeField.dates.includes(dia)) {
              arrHorarios[diaIndice[dia]] = horario;
            }
          }
        }
      }
    }
    const horariosDefinidos = arrHorarios.join(',')
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-horarios-estabelecimento.php`;
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      horarios: horariosDefinidos,
    });
    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso atualizar") {
            Cookies.set('hREst', horariosDefinidos, { expires: 7 });
            update("", `${Date.now()}___success___Seus horários foram alterados com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel alterar seus horarios. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleSalvarPagamentos = () => {

    const formasCreditos = creditos.replace(/,$/, '')
    const formasVales = vales.replace(/,$/, '')
    const formasDinheiro = dinheiro ? "dinheiro" : ""
    const formasLink = link ? "link" : ""

    let formasChave = ""
    if (chavePix && chavePix.length > 14) {
      formasChave = chavePix
    }

    const formasPagamento = `--${formasCreditos}--${formasVales}--${chavePix}--${formasDinheiro}--${formasLink}`
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-formas-pagamento.php`;
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      formaspagamento: formasPagamento,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso atualizar") {
            Cookies.set('fMsP', formasPagamento, { expires: 7 });
            update("", `${Date.now()}___success___Seus métodos de pagamentos foram atualizadas com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel alterar seus métodos de pagamento. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleSalvarTaxas = () => {
    let taxasFormatadas = formatRaioValor(taxas).join("--")

    if (valorCombinar) {
      taxasFormatadas = `CB__${taxasFormatadas}`;
    }
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-taxas.php`;
    const json = JSON.stringify({
      idestabelecimentos: idestabelecimentos,
      taxas: taxasFormatadas,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso atualizar") {
            Cookies.set('txEst', taxasFormatadas, { expires: 7 });
            update("", `${Date.now()}___success___Suas taxas foram atualizadas com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel atualizar suas taxas. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const formatRaioValor = (array) => {
    let formattedArray = [];

    for (const arr of array) {
      formattedArray.push(`${arr.raio}--${arr.valor}`);
    }
    return formattedArray;
  };

  const totalSelecionado = (it) => {
    switch (it) {
      case "cartoes":
        const crr = creditos ? creditos.replace(/,$/, '').split(",") : 0
        return `${crr.length} ${crr > 1 ? "selecionado" : "selecionados"}`
      case "vales":
        const vrr = vales ? vales.replace(/,$/, '').split(",") : 0
        return `${vrr.length} ${vrr > 1 ? "selecionado" : "selecionados"}`
      case "pix":
        const px = chavePix && chavePix.length > 14
        return `${px ? "1 chave" : "0 chave"}`
      case "outros":
        let count = 0
        count += dinheiro ? 1 : 0
        count += link ? 1 : 0
        return `${count} ${count > 1 ? "selecionado" : "selecionados"}`
    }

  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/png, image/jpeg"
        onChange={handleFileChange}
      />

      <div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Configurações</Typography>
              </Grid>

              <Grid item xs={12} style={{ borderBottom: "1px solid #CCC", marginLeft: 16, marginTop: 20, padding: 0 }}>
                <Tabs value={abaConfiguracao} onChange={handleChange} style={{ maxWidth: 570 }} variant="scrollable" scrollButtons="auto" >
                  <Tab label="Conta" {...a11yProps(0)} style={{ textTransform: "none" }} />
                  <Tab label="Notificações" {...a11yProps(1)} style={{ textTransform: "none" }} />
                  <Tab label="Horários" {...a11yProps(2)} style={{ textTransform: "none" }} />
                  <Tab label="Pagamentos" {...a11yProps(3)} style={{ textTransform: "none" }} />
                </Tabs>
              </Grid>

              {abaConfiguracao === 0 &&
                <Grid container spacing={0} style={{ padding: 24 }}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <List>
                          <ListItem disablePadding style={{ backgroundColor: abaConta === 0 ? "#F2F2F2" : "" }} secondaryAction={
                            <IconButton edge="end">
                              <KeyboardArrowRight />
                            </IconButton>
                          } onClick={(event) => handleContaChange(event, 0)}>
                            <ListItemButton>
                              <ListItemText primary="Informações de perfil" primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding style={{ backgroundColor: abaConta === 1 ? "#F2F2F2" : "" }} secondaryAction={
                            <IconButton edge="end">
                              <KeyboardArrowRight />
                            </IconButton>
                          } onClick={(event) => handleContaChange(event, 1)}>
                            <ListItemButton>
                              <ListItemText primary="E-mail" primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding style={{ backgroundColor: abaConta === 2 ? "#F2F2F2" : "" }} secondaryAction={
                            <IconButton edge="end">
                              <KeyboardArrowRight />
                            </IconButton>
                          } onClick={(event) => handleContaChange(event, 2)}>
                            <ListItemButton>
                              <ListItemText primary="Senha" primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding style={{ backgroundColor: abaConta === 3 ? "#F2F2F2" : "" }} secondaryAction={
                            <IconButton edge="end">
                              <KeyboardArrowRight />
                            </IconButton>
                          } onClick={(event) => handleContaChange(event, 3)}>
                            <ListItemButton>
                              <ListItemText primary="Endereço" primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </List>

                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B", padding: "10px 0px 0px 0px" }}>Outros</Typography>

                        <List>
                          {/* <ListItem disablePadding secondaryAction={
                              <IconButton edge="end">
                                <KeyboardArrowRight />
                              </IconButton>
                            }>
                              <ListItemButton>
                                <ListItemText primary="Excluir conta" primaryTypographyProps={{ fontWeight: "bold" }} />
                              </ListItemButton>
                            </ListItem>
                            <Divider /> */}
                          <ListItem disablePadding secondaryAction={
                            <IconButton edge="end">
                              <KeyboardArrowRight />
                            </IconButton>
                          }>
                            <ListItemButton>
                              <ListItemText onClick={handleLogout} primary="Sair" primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                  {abaConta === 0 &&
                    <Grid item xs={12} sm={12} md={8}>

                      <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Informações de perfil</Typography>
                        <Button onClick={handleSalvarInfoPerfil} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                          Salvar
                        </Button>
                      </div>

                      <div style={{ height: 24 }} />

                      <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                        <div style={{ padding: 24, display: "flex", alignItems: "center", border: "1px solid #CCC", borderRadius: 8 }}>
                          <Avatar src={selectedImage ? selectedImage : `${Cookies.get('UBs').replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`} style={{ width: 88, height: 88 }} />
                          <div style={{ width: 22 }} />
                          <div style={{ width: "100%" }}>
                            <Typography style={{ fontWeight: "bold", fontSize: 16 }}>Foto de perfil</Typography>
                            <Typography style={{ fontSize: 14 }}>Apenas imagens JPG e PNG até 5MB</Typography>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Button onClick={handleUploadFile} size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} startIcon={<UploadOutlined />}>
                                Carregar foto
                              </Button>
                              {selectedImage &&
                                <Button size="large" color="error" onClick={handleRemoveFile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }}>
                                  Remover
                                </Button>
                              }
                            </div>
                          </div>
                        </div>
                        <div style={{ height: 24 }} />
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Nome da loja <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={nomeLoja}
                          onChange={handleNomeLoja}
                          variant="outlined"
                          size="large"
                          placeholder="Digite aqui o nome da loja"
                          type="text"
                        />
                        <div style={{ height: 16 }} />
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Sobre nós <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={sobreNos}
                          onChange={handleSobreNos}
                          variant="outlined"
                          size="large"
                          placeholder="Digite aqui uma descrição"
                          type="text"
                        />
                        <div style={{ height: 16 }} />

                        {telefones.map((telefone, index) => (
                          <div key={index} style={{ marginBottom: '16px' }}>
                            <Typography style={{ display: "flex", alignItems: "center", textAlign: 'left', fontSize: '14px', fontWeight: '600' }}>
                              Telefone {index + 1} {index + 1 < 2 ? <b style={{ color: '#A51D1D' }}>*</b> : <></>}
                              {telefones.length > 1 && (
                                <Button
                                  color="error"
                                  onClick={() => removeTelefone(index)}
                                  style={{ textTransform: "none", marginLeft: "auto", fontWeight: "600" }}
                                >
                                  Remover
                                </Button>
                              )}
                            </Typography>
                            <TextField
                              fullWidth
                              value={telefone}
                              onChange={(event) => handleTelefoneChange(index, event)}
                              variant="outlined"
                              size="large"
                              placeholder="Ex.: 11912341234"
                              type="text"
                              inputProps={{ maxLength: 15 }}
                              style={{ marginBottom: '8px' }}
                            />

                          </div>
                        ))}
                        <Button
                          fullWidth
                          color="primary"
                          onClick={addTelefone}
                          disabled={telefones.length > 4}
                          startIcon={<Phone />}
                        >
                          Adicionar Telefone
                        </Button>

                        {/* <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Telefone 1 <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <TextField
                            fullWidth
                            value={telefone1}
                            onChange={handleTelefone1}
                            variant="outlined"
                            size="large"
                            placeholder="Ex.: 11912341234"
                            type="text"
                          /> */}


                      </div>

                    </Grid>
                  }
                  {abaConta === 1 &&
                    <Grid item xs={12} sm={12} md={8}>
                      <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>E-mail</Typography>
                        {/* <Button onClick={handleSalvarEmail} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                            Salvar
                          </Button> */}
                      </div>
                      <div style={{ height: 24 }} />
                      <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          E-mail
                          {/* <b style={{ color: "#A51D1D" }}>*</b> */}
                        </Typography>
                        <Tooltip title={"Para alterar o e-mail, entre em contato com nosso suporte"}>
                          <TextField
                            fullWidth
                            value={email}
                            onChange={handleEmail}
                            variant="outlined"
                            size="large"
                            style={{ background: "#f2f2f2" }}
                            disabled
                            placeholder="Insira o e-mail atual"
                            type="text"
                          />
                        </Tooltip>
                        {/* <div style={{ height: 16 }} />

                          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Novo e-mail <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <TextField
                            fullWidth
                            value={novoEmail}
                            onChange={handleNovoEmail}
                            variant="outlined"
                            size="large"
                            placeholder="Insira o novo e-mail"
                            type="text"
                          />

                          <div style={{ height: 16 }} />

                          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Confirmar e-mail <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <TextField
                            fullWidth
                            value={confirmarNovoEmail}
                            onChange={handleConfirmarNovoEmail}
                            variant="outlined"
                            size="large"
                            placeholder="Insira o novo e-mail novamente"
                            type="text"
                          />

                          <div style={{ height: 24 }} /> */}
                      </div>
                    </Grid>
                  }
                  {abaConta === 2 &&
                    <Grid item xs={12} sm={12} md={8}>
                      <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Senha</Typography>
                        <Button onClick={handleSalvarSenha} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                          Salvar
                        </Button>
                      </div>

                      <div style={{ height: 24 }} />
                      <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Senha <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={senha}
                          onChange={handleSenha}
                          variant="outlined"
                          size="large"
                          placeholder="Insira a senha atual"
                          type={showSenha ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <>
                                <InputAdornment style={{ cursor: "pointer" }}>
                                  {showSenha ?
                                    <VisibilityOutlined onClick={handleShowSenha} />
                                    :
                                    <VisibilityOffOutlined onClick={handleShowSenha} />
                                  }
                                </InputAdornment>
                              </>
                            ),
                          }}
                        />

                        <div style={{ height: 16 }} />

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Nova senha <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={novaSenha}
                          onChange={handleNovaSenha}
                          variant="outlined"
                          size="large"
                          placeholder="Insira a nova senha"
                          type={showNovaSenha ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <>
                                <InputAdornment style={{ cursor: "pointer" }}>
                                  {showNovaSenha ?
                                    <VisibilityOutlined onClick={handleShowNovaSenha} />
                                    :
                                    <VisibilityOffOutlined onClick={handleShowNovaSenha} />
                                  }
                                </InputAdornment>
                              </>
                            ),
                          }}
                        />

                        <div style={{ height: 16 }} />

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Confirmar senha <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={confirmarNovaSenha}
                          onChange={handleConfirmarNovaSenha}
                          variant="outlined"
                          size="large"
                          placeholder="Insira a nova senha novamente"
                          type={showConfirmaNovaSenha ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <>
                                <InputAdornment style={{ cursor: "pointer" }}>
                                  {showConfirmaNovaSenha ?
                                    <VisibilityOutlined onClick={handleShowConfirmaNovaSenha} />
                                    :
                                    <VisibilityOffOutlined onClick={handleShowConfirmaNovaSenha} />
                                  }
                                </InputAdornment>
                              </>
                            ),
                          }}
                        />

                        <div style={{ height: 24 }} />
                      </div>
                    </Grid>
                  }
                  {abaConta === 3 &&
                    <Grid item xs={12} sm={12} md={8}>
                      <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Endereço</Typography>
                        <Button onClick={handleSalvarEndereco} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                          Salvar
                        </Button>
                      </div>

                      <div style={{ height: 24 }} />
                      <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          CEP <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={cep}
                          onChange={handleCep}
                          variant="outlined"
                          size="large"
                          placeholder="Ex.: 12345000"
                          type="text"
                          inputProps={{
                            maxLength: 8,
                            inputMode: 'numeric',
                            pattern: '[0-9]*', // Atributo HTML que permite apenas números
                          }}
                        />

                        <div style={{ height: 16 }} />

                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Endereço <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={endereco}
                          onChange={handleEndereco}
                          variant="outlined"
                          size="large"
                          style={{ background: semLogradouro ? "" : "#f2f2f2" }}
                          disabled={!semLogradouro}
                          placeholder="Insira o endereço aqui"
                          type="text"
                        />
                        <div style={{ height: 16 }} />
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Número <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={numero}
                          onChange={handleNumero}
                          variant="outlined"
                          size="large"
                          style={{ background: hasAddress ? "" : "#f2f2f2" }}
                          disabled={!hasAddress}
                          placeholder="Número ou s/n"
                          type="text"
                        />
                        <div style={{ height: 16 }} />
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Complemento <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={complemento}
                          onChange={handleComplemento}
                          variant="outlined"
                          size="large"
                          style={{ background: hasAddress ? "" : "#f2f2f2" }}
                          disabled={!hasAddress}
                          placeholder="Sala, bloco"
                          type="text"
                        />
                        <div style={{ height: 16 }} />
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Estado <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={estado}
                          onChange={handleEstado}
                          variant="outlined"
                          size="large"
                          style={{ background: "#f2f2f2" }}
                          disabled
                          placeholder=""
                          type="text"
                        />
                        <div style={{ height: 16 }} />
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Cidade <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <TextField
                          fullWidth
                          value={cidade}
                          onChange={handleCidade}
                          variant="outlined"
                          size="large"
                          style={{ background: "#f2f2f2" }}
                          disabled
                          placeholder=""
                          type="text"
                        />

                        <div style={{ height: 24 }} />
                      </div>
                    </Grid>
                  }
                </Grid>
              }

              {abaConfiguracao === 1 &&
                <Grid item xs={12} sm={12} md={6}>
                  {/* <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold" }}>
                    Notificações gerais
                  </Typography>
                  <div style={{ height: 16 }} />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ border: "1px solid #CCC", borderRadius: 8 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: 14, color: "#1b1b1b", paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                            <b style={{ fontSize: 16 }}>Notificar antes de fechar a loja</b><br />
                            Ative para ser notificado
                          </Typography>
                          <FormControlLabel style={{ marginLeft: "auto" }}
                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                          />
                        </div>
                        <Divider style={{ marginLeft: 16, marginRight: 16 }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: 14, color: "#1b1b1b", paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                            <b style={{ fontSize: 16 }}>Som</b><br />
                            Ative para ser notificado com alerta sonoro
                          </Typography>
                          <FormControlLabel style={{ marginLeft: "auto" }}
                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{ height: 24 }} />
                  <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold" }}>
                    Notificações pedidos
                  </Typography>
                  <div style={{ height: 16 }} />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ border: "1px solid #CCC", borderRadius: 8 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: 14, color: "#1b1b1b", paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                            <b style={{ fontSize: 16 }}>Pedidos novos</b><br />
                            Ative para ser notificado
                          </Typography>
                          <FormControlLabel style={{ marginLeft: "auto" }}
                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                          />
                        </div>
                        <Divider style={{ marginLeft: 16, marginRight: 16 }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: 14, color: "#1b1b1b", paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                            <b style={{ fontSize: 16 }}>Atualização de pedidos</b><br />
                            Ative para ser notificado
                          </Typography>
                          <FormControlLabel style={{ marginLeft: "auto" }}
                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div style={{ height: 24 }} /> */}
                  <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold" }}>
                    Notificações estoque
                  </Typography>
                  <div style={{ height: 16 }} />
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ border: "1px solid #CCC", borderRadius: 8 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={{ fontSize: 14, color: "#1b1b1b", paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                            <b style={{ fontSize: 16 }}>Estoque minimo</b><br />
                            Ative para ser notificado ao atingir o estoque minimo
                          </Typography>
                          <FormControlLabel style={{ marginLeft: "auto" }}
                            control={<MaterialUISwitch sx={{ m: 1 }} checked={notificarEstoqueMinimo} onClick={handleChangeNotificarEstoqueMinimo} />}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              }

              {abaConfiguracao === 2 &&
                <>
                  <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: 16 }}>
                    <Alert
                      variant="outlined"
                      severity="info"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        color: "#464545", fontSize: 14,
                        border: "none",
                        background: "#ECF0F9"
                      }}
                    >
                      <div>
                        <b style={{ fontSize: 16 }}>Atenção com os horários</b><br />
                        Horários não preenchidos ou incorretos serão tratados como fechado
                      </div>
                    </Alert>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: 16, display: "flex", alignItems: "center" }}>
                    <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Configurar horários</Typography>
                    <Button onClick={handleSalvarHorarios} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                      Salvar
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div style={{ border: "1px solid #CCC", borderRadius: 8 }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography style={{ fontSize: 16, color: "#1b1b1b", fontWeight: "600", paddingTop: 24, paddingBottom: 16, paddingLeft: 16 }}>
                              Todos os dias
                            </Typography>
                            <FormControlLabel style={{ marginLeft: "auto" }}
                              control={<MaterialUISwitch sx={{ m: 1 }} checked={todosDias} onClick={handleChangeTodosDias} />}
                            />
                          </div>

                          <div style={{ display: "flex", alignItems: "center", pointerEvents: !todosDias ? 'none' : 'auto', opacity: !todosDias ? 0.5 : 1 }}>
                            <TextField
                              fullWidth
                              value={todosDiasInicio}
                              onChange={handleTodosDiasInicio}
                              variant="outlined"
                              size="large"
                              placeholder=""
                              type="text"
                              style={{ paddingLeft: 16, paddingRight: 8 }}
                            />
                            até
                            <TextField
                              fullWidth
                              value={todosDiasFim}
                              onChange={handleTodosDiasFim}
                              variant="outlined"
                              size="large"
                              placeholder=""
                              type="text"
                              style={{ paddingLeft: 8, paddingRight: 16 }}
                            />
                          </div>
                          <div style={{ height: 24 }} />
                        </div>
                      </Grid>
                    </Grid>
                    <div style={{ height: 24 }} />
                    <Typography style={{ color: "#1B1B1B", fontSize: "16px", fontWeight: "bold" }}>
                      Personalizar
                    </Typography>
                    <div style={{ height: 16 }} />
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div style={{ border: "1px solid #CCC", borderRadius: 8 }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography style={{ fontSize: 14, color: "#1b1b1b", paddingTop: 16, paddingBottom: 16, paddingLeft: 16 }}>
                              <b style={{ fontSize: 16 }}>Definir manualmente </b><br />
                              Ative para definir os horários da loja manualmente
                            </Typography>
                            <FormControlLabel style={{ marginLeft: "auto" }}
                              control={<MaterialUISwitch sx={{ m: 1 }} checked={definirManualmente} onClick={handleDefinirManualmente} />}
                            />
                          </div>
                          {timeFields.map((timeField, index) => (
                            <div style={{ pointerEvents: !definirManualmente ? 'none' : 'auto', opacity: !definirManualmente ? 0.5 : 1, }}>
                              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                <TextField
                                  fullWidth
                                  value={timeField.start}
                                  onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
                                  variant="outlined"
                                  size="large"
                                  placeholder="HH:MM"
                                  style={{ paddingLeft: 16, paddingRight: 8 }}
                                />
                                <Typography style={{ padding: '0 8px' }}>até</Typography>
                                <TextField
                                  fullWidth
                                  value={timeField.end}
                                  onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
                                  variant="outlined"
                                  size="large"
                                  placeholder="HH:MM"
                                  style={{ paddingLeft: 8, paddingRight: timeFields.length > 1 ? 0 : 16 }}
                                />
                                {timeFields.length > 1 && (
                                  <IconButton color="error" onClick={() => handleRemoveField(index)}>
                                    <RemoveCircleOutline />
                                  </IconButton>
                                )}

                                {/* <Chip variant={timeFields.length === "Todos" ? "filled" : "outlined"} label="Todos" icon={segment === "Todos" ? <Check style={{ color: "#FFF" }} /> : ""} style={{ borderRadius: "4px", marginRight: "8px", backgroundColor: segment === "Todos" ? "#1B1B1B" : "", color: segment === "Todos" ? "#FFF" : "#1B1B1B" }} onClick={() => handleSegment("Todos")} /> */}


                              </div>
                              <div style={{ height: 10 }} />
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 12, paddingRight: 12 }}>
                                <Chip variant={timeField.dates && timeField.dates.includes("S,") ? "filled" : "outlined"} label="Seg" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("S,") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("S,") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'S,')} />
                                <Chip variant={timeField.dates && timeField.dates.includes("T") ? "filled" : "outlined"} label="Ter" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("T") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("T") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'T')} />
                                <Chip variant={timeField.dates && timeField.dates.includes("Q,") ? "filled" : "outlined"} label="Qua" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("Q,") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("Q,") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'Q,')} />
                                <Chip variant={timeField.dates && timeField.dates.includes("QI") ? "filled" : "outlined"} label="Qui" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("QI") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("QI") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'QI')} />
                                {!isMobile && <>
                                  <Chip variant={timeField.dates && timeField.dates.includes("SX") ? "filled" : "outlined"} label="Sex" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("SX") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("SX") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'SX')} />
                                  <Chip variant={timeField.dates && timeField.dates.includes("SB") ? "filled" : "outlined"} label="Sab" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("SB") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("SB") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'SB')} />
                                  <Chip variant={timeField.dates && timeField.dates.includes("D") ? "filled" : "outlined"} label="Dom" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("D") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("D") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'D')} />
                                  <Chip variant={timeField.dates && timeField.dates.includes("FR") ? "filled" : "outlined"} label="Fer" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("FR") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("FR") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'FR')} />
                                </>
                                }
                              </div>
                              {isMobile &&
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 12, paddingRight: 12, paddingTop: 8 }}>
                                  <Chip variant={timeField.dates && timeField.dates.includes("SX") ? "filled" : "outlined"} label="Sex" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("SX") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("SX") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'SX')} />
                                  <Chip variant={timeField.dates && timeField.dates.includes("SB") ? "filled" : "outlined"} label="Sab" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("SB") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("SB") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'SB')} />
                                  <Chip variant={timeField.dates && timeField.dates.includes("D") ? "filled" : "outlined"} label="Dom" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("D") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("D") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'D')} />
                                  <Chip variant={timeField.dates && timeField.dates.includes("FR") ? "filled" : "outlined"} label="Fer" style={{ width: "100%", borderRadius: "4px", marginLeft: "4px", marginRight: "4px", backgroundColor: timeField.dates && timeField.dates.includes("FR") ? "#1B1B1B" : "", color: timeField.dates && timeField.dates.includes("FR") ? "#FFF" : "#1B1B1B" }} onClick={() => handleDiaChange(index, 'FR')} />
                                </div>
                              }
                              {timeFields.length > 1 && (
                                <>
                                  <div style={{ height: 6 }} />
                                  <Divider style={{ margin: 16 }} />
                                  <div style={{ height: 6 }} />
                                </>
                              )}
                            </div>
                          ))}
                          <div style={{ height: 16 }} />

                          <Button
                            style={{ pointerEvents: !definirManualmente ? 'none' : 'auto', opacity: !definirManualmente ? 0.5 : 1, }}
                            fullWidth
                            color="primary"
                            onClick={handleAddField}
                            startIcon={<Add />}
                          >
                            Adicionar outro
                          </Button>

                          <div style={{ height: 24 }} />

                        </div>
                      </Grid>
                    </Grid>

                  </Grid>
                </>
              }

              {abaConfiguracao === 3 &&
                <Grid container spacing={0} style={{ padding: 24 }}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <List>
                          <ListItem disablePadding style={{ backgroundColor: abaPagamentos === 0 ? "#F2F2F2" : "" }} onClick={(event) => handlePagamentosChange(event, 0)}>
                            <ListItemButton>
                              <ListItemText primary={<Typography style={{ display: "flex", alignItems: "center" }}><PersonOutlineOutlined /> &nbsp;&nbsp;&nbsp;<b style={{ paddingTop: 4 }}>Métodos de pagamentos</b></Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding style={{ backgroundColor: abaPagamentos === 1 ? "#F2F2F2" : "" }} onClick={(event) => handlePagamentosChange(event, 1)}>
                            <ListItemButton>
                              <ListItemText primary={<Typography style={{ display: "flex", alignItems: "center" }}><PersonOutlineOutlined /> &nbsp;&nbsp;&nbsp;<b style={{ paddingTop: 4 }}>Taxas de entrega</b></Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                  {abaPagamentos === 0 &&
                    <Grid item xs={12} sm={12} md={8}>

                      <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", alignItems: "center", paddingTop: isMobile ? 16 : 0 }}>
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Métodos de pagamentos</Typography>
                        <Button onClick={handleSalvarPagamentos} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                          Salvar
                        </Button>
                      </div>

                      <div style={{ height: 24 }} />

                      <div style={{ paddingLeft: isMobile ? 0 : 24 }}>

                        <Accordion expanded={expandedCredito} onChange={handleExpanded("credito")} style={{ border: "solid 1px #dfdfdf" }}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography style={{ display: "flex", alignItems: "center", width: "100%" }}>
                              <CreditCard />&nbsp;&nbsp;&nbsp;Cartões de débito e crédito
                              <Typography style={{ fontSize: 14, color: "#999999", marginLeft: "auto", paddingTop: 2, marginRight: 4 }}>{totalSelecionado('cartoes')}</Typography>
                            </Typography>
                            <div style={{ height: 40 }} />
                          </AccordionSummary>
                          <AccordionDetails>

                            <FormGroup style={{ marginRight: "auto", marginLeft: 16 }}>
                              <FormControlLabel control={<Checkbox checked={selecionarTodosCartoes} onChange={handleSelecionarTodosCartoes} />} label={<Typography style={{ fontSize: 14 }}>&nbsp;&nbsp;&nbsp;Selecionar todos</Typography>} />
                            </FormGroup>

                            <Grid container spacing={0} style={{ paddingTop: 16, paddingBottom: 16 }}>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("mastercard")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("mastercard")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Mastercard
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("visa")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("visa")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Visa
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("elo")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("elo")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Elo
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("americanexpress")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("americanexpress")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    American Express
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("banricompras")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("banricompras")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Banri Compras
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("verdecard")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("verdecard")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Verdecard
                                  </Typography>
                                </div>
                              </Grid>

                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("hipercard")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("hipercard")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Hipercard
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("dinnersclub")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("dinnersclub")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Dinners Club
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("aura")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("aura")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Aura
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("credicard")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("credicard")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Credicard
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("sorocred")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("sorocred")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Sorocred
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddCredito("pan")} style={{ background: selecionarTodosCartoes || (creditos && creditos.includes("pan")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CreditCard /><br />
                                    Pan
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <div style={{ height: 16 }} />
                        <Accordion expanded={expandedVales} onChange={handleExpanded("vales")} style={{ border: "solid 1px #dfdfdf" }}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography style={{ display: "flex", alignItems: "center", width: "100%" }}>
                              <CardMembershipOutlined />&nbsp;&nbsp;&nbsp;Vale alimentação e refeição
                              <Typography style={{ fontSize: 14, color: "#999999", marginLeft: "auto", paddingTop: 2, marginRight: 4 }}>{totalSelecionado('vales')}</Typography>
                            </Typography>
                            <div style={{ height: 40 }} />
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup style={{ marginRight: "auto", marginLeft: 16 }}>
                              <FormControlLabel control={<Checkbox checked={selecionarTodosVales} onChange={handleSelecionarTodosVales} />} label={<Typography style={{ fontSize: 14 }}>&nbsp;&nbsp;&nbsp;Selecionar todos</Typography>} />
                            </FormGroup>

                            <Grid container spacing={0} style={{ paddingTop: 16, paddingBottom: 16 }}>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("alelo")} style={{ background: selecionarTodosVales || (vales && vales.includes("alelo")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    Alelo
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("sodexo")} style={{ background: selecionarTodosVales || (vales && vales.includes("sodexo")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    Sodexo
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("ticket")} style={{ background: selecionarTodosVales || (vales && vales.includes("ticket")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    Ticket
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("vrbeneficios")} style={{ background: selecionarTodosVales || (vales && vales.includes("vrbeneficios")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    VR Beneficios
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("flash")} style={{ background: selecionarTodosVales || (vales && vales.includes("flash")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    Flash
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("greencard")} style={{ background: selecionarTodosVales || (vales && vales.includes("greencard")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    Green
                                  </Typography>
                                </div>
                              </Grid>

                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("vb")} style={{ background: selecionarTodosVales || (vales && vales.includes("vb")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    VB
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleAddVales("ifoodalimentacao")} style={{ background: selecionarTodosVales || (vales && vales.includes("ifoodalimentacao")) ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <CardMembershipOutlined /><br />
                                    Ifood Alimentação
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <div style={{ height: 16 }} />
                        <Accordion expanded={expandedPix} onChange={handleExpanded("pix")} style={{ border: "solid 1px #dfdfdf" }}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography style={{ display: "flex", alignItems: "center", width: "100%" }}>
                              <PixOutlined />&nbsp;&nbsp;&nbsp;PIX
                              <Typography style={{ fontSize: 14, color: "#999999", marginLeft: "auto", paddingTop: 2, marginRight: 4 }}>{totalSelecionado('pix')}</Typography>
                            </Typography>
                            <div style={{ height: 40 }} />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={0} style={{ paddingTop: 16, paddingBottom: 16 }}>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleTipoChave("email")} style={{ background: tipoChave === "email" ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <EmailOutlined /><br />
                                    E-mail
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleTipoChave("cpf")} style={{ background: tipoChave === "cpf" ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <PermIdentityOutlined /><br />
                                    CPF ou CNPJ
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={2}>
                                <div onClick={() => handleTipoChave("telefone")} style={{ background: tipoChave === "telefone" ? "#d9d9d9" : "", cursor: "pointer", border: "solid 1px #999999", borderRadius: 8, paddingTop: 12, paddingBottom: 12, margin: 4, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: 100 }}>
                                  <Typography style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
                                    <PhoneOutlined /><br />
                                    Telefone
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>

                            <Divider />
                            <div style={{ height: 16 }} />
                            <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                              Chave pix
                            </Typography>
                            <TextField
                              fullWidth
                              value={chavePix}
                              onChange={handleChave}
                              variant="outlined"
                              size="large"
                              placeholder="Digite aqui sua chave pix"
                              type="text"
                              inputProps={{ maxLength: tipoChave === "cpf" ? 18 : 15 }}
                            />
                            <div style={{ height: 16 }} />
                          </AccordionDetails>
                        </Accordion>
                        <div style={{ height: 16 }} />
                        <Accordion expanded={expandedOutros} onChange={handleExpanded("outros")} style={{ border: "solid 1px #dfdfdf" }}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography style={{ display: "flex", alignItems: "center", width: "100%" }}>
                              <CreditCard />&nbsp;&nbsp;&nbsp;Outros
                              <Typography style={{ fontSize: 14, color: "#999999", marginLeft: "auto", paddingTop: 2, marginRight: 4 }}>{totalSelecionado('outros')}</Typography>
                            </Typography>
                            <div style={{ height: 40 }} />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ height: 16 }} />

                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography style={{ fontSize: 14, color: "#1b1b1b", fontWeight: "400" }}>
                                <b style={{ fontSize: 16 }}>Dinheiro</b><br />
                                Ative para aceitar pagamentos em dinheiro
                              </Typography>
                              <FormControlLabel style={{ marginLeft: "auto" }}
                                control={<MaterialUISwitch sx={{ m: 1 }} checked={dinheiro} onClick={handleChangeDinheiro} />}
                              />
                            </div>
                            <div style={{ height: 16 }} />
                            <Divider />
                            <div style={{ height: 16 }} />
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography style={{ fontSize: 14, color: "#1b1b1b", fontWeight: "400" }}>
                                <b style={{ fontSize: 16 }}>Link de pagamento</b><br />
                                Ative para aceitar pagamentos via link
                              </Typography>
                              <FormControlLabel style={{ marginLeft: "auto" }}
                                control={<MaterialUISwitch sx={{ m: 1 }} checked={link} onClick={handleLink} />}
                              />
                            </div>

                            <div style={{ height: 16 }} />
                          </AccordionDetails>
                        </Accordion>

                      </div>

                    </Grid>
                  }
                  {abaPagamentos === 1 &&
                    <Grid item xs={12} sm={12} md={8}>
                      <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", alignItems: "center", paddingTop: isMobile ? 16 : 0 }}>
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Taxas de entrega</Typography>
                        <Button onClick={handleSalvarTaxas} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                          Salvar
                        </Button>
                      </div>

                      <div style={{ height: 24 }} />

                      <div style={{ paddingLeft: isMobile ? 0 : 24 }}>


                        <div style={{ border: "1px solid #CCC", padding: 24, borderRadius: 8 }}>

                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography style={{ fontSize: 14, color: "#1b1b1b", fontWeight: "400" }}>
                              <b style={{ fontSize: 18 }}>Valor a combinar </b><br />
                              Ative para combinar o valor pelo whatsapp
                            </Typography>
                            <FormControlLabel style={{ marginLeft: "auto" }}
                              control={<MaterialUISwitch sx={{ m: 1 }} checked={valorCombinar} onClick={handleChangeCombinar} />}
                            />
                          </div>
                          <div style={{ height: 16 }} />
                          <Divider />
                          <div style={{ height: 24 }} />

                          <div style={{ marginBottom: '16px', display: "flex" }}>
                            <Typography style={{ fontSize: 18, fontWeight: '600', width: "100%" }}>
                              Raio (km)
                            </Typography>
                            <Typography style={{ fontSize: 18, fontWeight: '600', width: "100%" }}>
                              Valor cobrado
                            </Typography>
                          </div>

                          {taxas.map((taxa, index) => (
                            <>
                              <div style={{ marginBottom: '16px', display: "flex", alignItems: "center", pointerEvents: valorCombinar ? 'none' : 'auto', opacity: valorCombinar ? 0.5 : 1 }}>
                                <TextField
                                  fullWidth
                                  value={taxa.raio}
                                  onChange={(event) => handleTaxaChange(index, 'raio', event)}
                                  variant="outlined"
                                  size="large"
                                  placeholder="Ex.: 2 (km)"
                                  type="text"
                                  inputProps={{ maxLength: 3 }}
                                  style={{ marginBottom: '8px' }}
                                />
                                <div style={{ width: 16 }} />
                                <TextField
                                  fullWidth
                                  value={taxa.valor}
                                  onChange={(event) => handleTaxaChange(index, 'valor', event)}
                                  variant="outlined"
                                  size="large"
                                  placeholder="Ex.: 10,00 (R$)"
                                  type="text"
                                  inputProps={{ maxLength: 15 }}
                                  style={{ marginBottom: '8px' }}
                                />
                                <IconButton color="error" onClick={() => removeTaxaField(index)}>
                                  <DeleteOutline />
                                </IconButton>
                              </div>
                            </>
                          ))}
                          <Button
                            fullWidth
                            color="primary"
                            onClick={addTaxaField}
                            startIcon={<Add />}
                            style={{ pointerEvents: valorCombinar ? 'none' : 'auto', opacity: valorCombinar ? 0.5 : 1 }}
                          >
                            Adicionar outro
                          </Button>
                        </div>
                      </div>

                    </Grid>
                  }
                </Grid>
              }
            </Grid>
          </Grid>
          {isMobile &&
            <SwipeableDrawer
              container={container}
              anchor="bottom"
              open={abaConta !== -1}
              onClose={() => setAbaConta(-1)}
              disableSwipeToOpen={true}
              disableDiscovery={true}
              ModalProps={{
                keepMounted: true,
                style: { zIndex: "10000" }
              }}
            >
              <div style={{ padding: 16 }}>
                {abaConta === 0 &&
                  <Grid item xs={12} sm={12} md={8}>

                    <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", alignItems: "center" }}>
                      <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Informações de perfil</Typography>
                      <Button onClick={handleSalvarInfoPerfil} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                        Salvar
                      </Button>
                    </div>

                    <div style={{ height: 24 }} />

                    <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                      <div style={{ padding: 24, display: "flex", alignItems: "center", border: "1px solid #CCC", borderRadius: 8 }}>
                        <Avatar src={selectedImage ? selectedImage : `${Cookies.get('UBs').replaceAll("/api", "")}/imagens/logo-store.png?${new Date().getTime()}`} style={{ width: 88, height: 88 }} />
                        <div style={{ width: 22 }} />
                        <div style={{ width: "100%" }}>
                          <Typography style={{ fontWeight: "bold", fontSize: 16 }}>Foto de perfil</Typography>
                          <Typography style={{ fontSize: 14 }}>Apenas imagens JPG e PNG até 5MB</Typography>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Button onClick={handleUploadFile} size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} startIcon={<UploadOutlined />}>
                              Carregar foto
                            </Button>
                            {selectedImage &&
                              <Button size="large" color="error" onClick={handleRemoveFile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }}>
                                Remover
                              </Button>
                            }
                          </div>
                        </div>
                      </div>
                      <div style={{ height: 24 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Nome da loja <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={nomeLoja}
                        onChange={handleNomeLoja}
                        variant="outlined"
                        size="large"
                        placeholder="Digite aqui o nome da loja"
                        type="text"
                      />
                      <div style={{ height: 16 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Sobre nós <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={sobreNos}
                        onChange={handleSobreNos}
                        variant="outlined"
                        size="large"
                        placeholder="Digite aqui uma descrição"
                        type="text"
                      />
                      <div style={{ height: 16 }} />

                      {telefones.map((telefone, index) => (
                        <div key={index} style={{ marginBottom: '16px' }}>
                          <Typography style={{ display: "flex", alignItems: "center", textAlign: 'left', fontSize: '14px', fontWeight: '600' }}>
                            Telefone {index + 1} {index + 1 < 2 ? <b style={{ color: '#A51D1D' }}>*</b> : <></>}
                            {telefones.length > 1 && (
                              <Button
                                color="error"
                                onClick={() => removeTelefone(index)}
                                style={{ textTransform: "none", marginLeft: "auto", fontWeight: "600" }}
                              >
                                Remover
                              </Button>
                            )}
                          </Typography>
                          <TextField
                            fullWidth
                            value={telefone}
                            onChange={(event) => handleTelefoneChange(index, event)}
                            variant="outlined"
                            size="large"
                            placeholder="Ex.: 11912341234"
                            type="text"
                            inputProps={{ maxLength: 15 }}
                            style={{ marginBottom: '8px' }}
                          />

                        </div>
                      ))}
                      <Button
                        fullWidth
                        color="primary"
                        onClick={addTelefone}
                        disabled={telefones.length > 4}
                        startIcon={<Phone />}
                      >
                        Adicionar Telefone
                      </Button>

                      {/* <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Telefone 1 <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <TextField
                            fullWidth
                            value={telefone1}
                            onChange={handleTelefone1}
                            variant="outlined"
                            size="large"
                            placeholder="Ex.: 11912341234"
                            type="text"
                          /> */}


                    </div>

                  </Grid>
                }
                {abaConta === 1 &&
                  <Grid item xs={12} sm={12} md={8}>
                    <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", alignItems: "center" }}>
                      <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>E-mail</Typography>
                      {/* <Button onClick={handleSalvarEmail} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                            Salvar
                          </Button> */}
                    </div>
                    <div style={{ height: 24 }} />
                    <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        E-mail
                        {/* <b style={{ color: "#A51D1D" }}>*</b> */}
                      </Typography>
                      <Tooltip title={"Para alterar o e-mail, entre em contato com nosso suporte"}>
                        <TextField
                          fullWidth
                          value={email}
                          onChange={handleEmail}
                          variant="outlined"
                          size="large"
                          style={{ background: "#f2f2f2" }}
                          disabled
                          placeholder="Insira o e-mail atual"
                          type="text"
                        />
                      </Tooltip>
                      {/* <div style={{ height: 16 }} />

                          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Novo e-mail <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <TextField
                            fullWidth
                            value={novoEmail}
                            onChange={handleNovoEmail}
                            variant="outlined"
                            size="large"
                            placeholder="Insira o novo e-mail"
                            type="text"
                          />

                          <div style={{ height: 16 }} />

                          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Confirmar e-mail <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <TextField
                            fullWidth
                            value={confirmarNovoEmail}
                            onChange={handleConfirmarNovoEmail}
                            variant="outlined"
                            size="large"
                            placeholder="Insira o novo e-mail novamente"
                            type="text"
                          />

                          <div style={{ height: 24 }} /> */}
                    </div>
                  </Grid>
                }
                {abaConta === 2 &&
                  <Grid item xs={12} sm={12} md={8}>
                    <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", display: "flex", alignItems: "center" }}>
                      <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Senha</Typography>
                      <Button onClick={handleSalvarSenha} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                        Salvar
                      </Button>
                    </div>

                    <div style={{ height: 24 }} />
                    <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Senha <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={senha}
                        onChange={handleSenha}
                        variant="outlined"
                        size="large"
                        placeholder="Insira a senha atual"
                        type={showSenha ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment style={{ cursor: "pointer" }}>
                                {showSenha ?
                                  <VisibilityOutlined onClick={handleShowSenha} />
                                  :
                                  <VisibilityOffOutlined onClick={handleShowSenha} />
                                }
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />

                      <div style={{ height: 16 }} />

                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Nova senha <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={novaSenha}
                        onChange={handleNovaSenha}
                        variant="outlined"
                        size="large"
                        placeholder="Insira a nova senha"
                        type={showNovaSenha ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment style={{ cursor: "pointer" }}>
                                {showNovaSenha ?
                                  <VisibilityOutlined onClick={handleShowNovaSenha} />
                                  :
                                  <VisibilityOffOutlined onClick={handleShowNovaSenha} />
                                }
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />

                      <div style={{ height: 16 }} />

                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Confirmar senha <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={confirmarNovaSenha}
                        onChange={handleConfirmarNovaSenha}
                        variant="outlined"
                        size="large"
                        placeholder="Insira a nova senha novamente"
                        type={showConfirmaNovaSenha ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment style={{ cursor: "pointer" }}>
                                {showConfirmaNovaSenha ?
                                  <VisibilityOutlined onClick={handleShowConfirmaNovaSenha} />
                                  :
                                  <VisibilityOffOutlined onClick={handleShowConfirmaNovaSenha} />
                                }
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />

                      <div style={{ height: 24 }} />
                    </div>
                  </Grid>
                }
                {abaConta === 3 &&
                  <Grid item xs={12} sm={12} md={8}>
                    <div style={{ paddingLeft: isMobile ? 0 : 24, display: "flex", display: "flex", alignItems: "center" }}>
                      <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Endereço</Typography>
                      <Button onClick={handleSalvarEndereco} size="medium" variant="contained" style={{ textTransform: 'none', marginLeft: "auto", fontWeight: "500" }}>
                        Salvar
                      </Button>
                    </div>

                    <div style={{ height: 24 }} />
                    <div style={{ paddingLeft: isMobile ? 0 : 24 }}>
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        CEP <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={cep}
                        onChange={handleCep}
                        variant="outlined"
                        size="large"
                        placeholder="Ex.: 12345000"
                        type="text"
                        inputProps={{
                          maxLength: 8,
                          inputMode: 'numeric',
                          pattern: '[0-9]*', // Atributo HTML que permite apenas números
                        }}
                      />

                      <div style={{ height: 16 }} />

                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Endereço <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={endereco}
                        onChange={handleEndereco}
                        variant="outlined"
                        size="large"
                        style={{ background: semLogradouro ? "" : "#f2f2f2" }}
                        disabled={!semLogradouro}
                        placeholder="Insira o endereço aqui"
                        type="text"
                      />
                      <div style={{ height: 16 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Número <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={numero}
                        onChange={handleNumero}
                        variant="outlined"
                        size="large"
                        style={{ background: hasAddress ? "" : "#f2f2f2" }}
                        disabled={!hasAddress}
                        placeholder="Número ou s/n"
                        type="text"
                      />
                      <div style={{ height: 16 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Complemento <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={complemento}
                        onChange={handleComplemento}
                        variant="outlined"
                        size="large"
                        style={{ background: hasAddress ? "" : "#f2f2f2" }}
                        disabled={!hasAddress}
                        placeholder="Sala, bloco"
                        type="text"
                      />
                      <div style={{ height: 16 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Estado <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={estado}
                        onChange={handleEstado}
                        variant="outlined"
                        size="large"
                        style={{ background: "#f2f2f2" }}
                        disabled
                        placeholder=""
                        type="text"
                      />
                      <div style={{ height: 16 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Cidade <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <TextField
                        fullWidth
                        value={cidade}
                        onChange={handleCidade}
                        variant="outlined"
                        size="large"
                        style={{ background: "#f2f2f2" }}
                        disabled
                        placeholder=""
                        type="text"
                      />

                      <div style={{ height: 24 }} />
                    </div>
                  </Grid>
                }
              </div>
            </SwipeableDrawer>
          }
        </Grid>
      </div>

    </ThemeProvider>
  );
}

export default Configuracoes;