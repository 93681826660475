import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { a11yProps, createNewTheme, maskReais, toReal } from '../../functions/utils';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Box, Button, Card, CardContent, CardMedia, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Radio, RadioGroup, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Add, AddOutlined, CheckCircle, CheckCircleOutline, Close, CloseOutlined, ContentCopyOutlined, DeleteOutline, EditOutlined, ErrorOutline, InsertDriveFileOutlined, KeyboardArrowDown, KeyboardArrowRight, MoreVert, RemoveOutlined, RemoveRedEyeOutlined, Star, StarBorder, StarBorderOutlined, ToggleOffOutlined, ToggleOnOutlined, Upload, UploadOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, MaterialUISwitch } from '../../components/utils';
import axios from 'axios';
import Cookies from 'js-cookie';

function Cardapio({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openModalProduto, setOpenModalProduto] = useState(false);
  const [openModalComplemento, setOpenModalComplemento] = useState(false);
  const [isEditingComplemento, setIsEditingComplemento] = useState(false);
  const [novoGrupo, setNovoGrupo] = useState(false);
  const [grupoOriginal, setGrupoOriginal] = useState("");
  const [indexEdit, setIndexEdit] = useState(0);
  const [isEditingGrupo, setIsEditingGrupo] = useState(false);
  const [importM, setImport] = useState(false);
  const [disableEditNomeGrupo, setDisableEditNomeGrupo] = useState(false);
  const [abaCardapio, setAbaCardapio] = useState(0);
  const [abaComplementos, setAbaComplementos] = useState(0);
  const fileInputRef = useRef(null);
  const [fileImport, setFileImport] = useState({ name: '', size: '' });
  const [fileImportar, setFileImportar] = useState(null);

  const [categoriaProduto, setCategoriaProduto] = useState("");
  const [isPizza, setIsPizza] = useState("");
  const [qtdSabores, setQtdSabores] = useState("2");

  const [nomeProduto, setNomeProduto] = useState("");
  const [descricaoProduto, setDescricaoProduto] = useState("");
  const [novaCategoria, setNovaCategoria] = useState("");
  const [nomeGrupo, setNomeGrupo] = useState("");
  const [nomeComplemento, setNomeComplemento] = useState("");
  const [descricaoComplemento, setDescricaoComplemento] = useState("");
  const [precoComplemento, setPrecoComplemento] = useState("");
  const [grupoSelected, setGrupoSelected] = useState("");

  const [precoProduto, setPrecoProduto] = useState("");
  const [descontoProduto, setDescontoProduto] = useState("");
  const [valorPontos, setValorPontos] = useState("");
  const [valorBroto, setValorBroto] = useState("");
  const [estoqueProduto, setEstoqueProduto] = useState("");
  const [limiteOpcionais, setLimiteOpcionais] = useState("");

  const [uploadProgress, setUploadProgress] = useState(0);
  const [importando, setImportando] = useState(false);
  const [aplicarDesconto, setAplicarDesconto] = useState(false);
  const [habilitaResgate, setHabilitaResgate] = useState(false);
  const [habilitaUnitario, setHabilitaUnitario] = useState(true);
  const [possuiEstoque, setPossuiEstoque] = useState(false);
  const [isObrigatorio, setIsObrigatorio] = useState(false);
  const [isGeral, setIsGeral] = useState(false);
  const [isOpcional, setIsOpcional] = useState(false);
  const [adicionarOutroComplemento, setAdicionarOutroComplemento] = useState(false);
  const [grupoExistente, setGrupoExistente] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEditOpc, setAnchorElEditOpc] = useState(null);
  const [anchorElEditGrupoOpc, setAnchorElEditGrupoOpc] = useState(null);
  const [anchorElEditItem, setAnchorElEditItem] = useState(null);
  const [selectedComplemento, setSelectedComplemento] = useState(null);

  const openStatus = Boolean(anchorEl);
  const openEditOpc = Boolean(anchorElEditOpc);
  const openEditGrupoOpc = Boolean(anchorElEditGrupoOpc);
  const openEditItem = Boolean(anchorElEditItem);

  const [categorias, setCategorias] = useState([]);
  const [items, setItems] = useState(null);
  const [itemSelected, setItemSelected] = useState("");
  const [itemsOrigim, setItemsOrigim] = useState([]);
  const [urlBase, setUrlBase] = useState();
  const [expandedArr, setExpandedArr] = useState([]);
  const [addCategoria, setAddCategoria] = useState(false);
  const [complementosArray, setComplementosArray] = useState([]);

  const [itensSelected, setItensSelected] = useState("");
  const [nomeItensSelected, setNomeItensSelected] = useState("");

  const [imagensProduto, setImagensProduto] = useState([]);
  const [filesImagens, setFilesImagens] = useState([]);
  const fileInputRefProdutos = useRef(null);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    e.target.value = null;
    if (file) {
      const newFilesImagens = [...filesImagens];
      newFilesImagens[index] = file;
      setFilesImagens(newFilesImagens);
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImagensProduto = [...imagensProduto];
        newImagensProduto[index] = reader.result;
        setImagensProduto(newImagensProduto);
      };
      reader.readAsDataURL(file);
      if (fileInputRefProdutos.current) {
        fileInputRefProdutos.current.value = null;
      }
    }
  };

  const handleCarregarFoto = () => {
    if (fileInputRefProdutos.current) {
      fileInputRefProdutos.current.click()
    }
  }
  const handleRemoveImage = (index) => {
    const newImagensProduto = imagensProduto.filter((_, i) => i !== index);
    setImagensProduto(newImagensProduto);
    const newFilesImagens = filesImagens.filter((_, i) => i !== index);
    setFilesImagens(newFilesImagens);
    if (fileInputRefProdutos.current) {
      fileInputRefProdutos.current.value = null;
    }
  };

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpandedArr((prevExpandedArr) => ({
      ...prevExpandedArr,
      [panel]: newExpanded !== undefined ? newExpanded : true,
    }));
  };

  const handleChange = (event, newValue) => {
    setAbaCardapio(newValue);
  };

  const handleChangeComplementos = (event, newValue) => {
    setAbaComplementos(newValue);
  };

  const handleImportCsv = () => {
    fileInputRef.current.click();
  };

  const handleRemoveCsv = () => {
    setFileImportar(null);
    setFileImport({ name: '', size: '' })
    fileInputRef.current.value = null;
  };

  const handleOpenModalImport = () => {
    setAbaCardapio(0)
    setOpenModalProduto(true);
    setImport(true);
  };

  const handleEditarGrupoComplementos = (grupo) => {
    setGrupoSelected(true);
    setDisableEditNomeGrupo(true)
    setNomeGrupo(grupo.nomeGrupo)
    setLimiteOpcionais(grupo.vLimiteOpcionais)
    setIsGeral(grupo.isGeral)
    setIsObrigatorio(grupo.isObrigatorio)
    setIsOpcional(grupo.vIsOpcional)
    setOpenModalComplemento(true);
    setGrupoOriginal(grupo.nomeGrupo)
    setNovoGrupo(false)
  };

  const handleCriarGrupoComplementos = () => {
    setDisableEditNomeGrupo(false)
    setNomeGrupo("")
    setLimiteOpcionais("")
    setIsGeral(false)
    setIsObrigatorio(false)
    setIsOpcional(false)
    setOpenModalComplemento(true);
    setIsEditingComplemento(false)
    setIsEditingGrupo(false)
    setGrupoOriginal("")
    setNovoGrupo(true)
  };

  const handleCloseModalComplemento = () => {
    setOpenModalComplemento(false);
  };

  const handleOpenModalProduto = () => {
    setItemSelected("")
    setOpenModalProduto(true);
    setAbaCardapio(0);
    limpaAdicionar()
    setImport(false);
  };

  const handleCloseModalProduto = () => {
    setOpenModalProduto(false);
  };

  const handleCategoriaProduto = (event) => {
    (event.target.value.toLowerCase().includes("pizza")) ? setIsPizza(true) : setIsPizza(false)
    setComplementosArray([])
    for (const item of items) {
      if (item.tipo && `${item.categoriaNumero} - ${item.categoriaOriginal}` === event.target.value && !item.categoriaProduto) {
        let nomeGrupo = item.subcategoriaOriginal, vLimiteOpcionais = item.limite, vIsObrigatorio = item.tipo.includes("true"), vIsGeral = !item.categoriaProduto
        let nomeComplemento = item.nome, descricaoComplemento = item.descricao, precoComplemento = item.valor
        let vIsOpcional = item.tipo.includes("opcionais"), adicionarOutroComplemento = false, idProduto = item.idprodutos
        const optionObject = {
          idProduto,
          nomeGrupo,
          vLimiteOpcionais,
          vIsGeral,
          vIsObrigatorio,
          nomeComplemento,
          descricaoComplemento,
          precoComplemento,
          vIsOpcional,
          adicionarOutroComplemento
        };
        setComplementosArray(prevArray => [...prevArray, optionObject]);
      }
    }
    setCategoriaProduto(event.target.value);
  };

  const handleAplicarDesconto = () => {
    setAplicarDesconto(!aplicarDesconto)
  };

  const handleHabilitaResgate = () => {
    setHabilitaResgate(!habilitaResgate)
  };

  const handleHabilitaUnitario = () => {
    setHabilitaUnitario(!habilitaUnitario)
  };

  const validaDesativar = () => {
    if (complementosArray) {
      return complementosArray.some(complemento => complemento.nomeGrupo === nomeGrupo);
    }
    return false;
  };

  const validaIsGeral = () => {

    if (disableEditNomeGrupo && complementosArray && complementosArray.length > 0) {
      // return complementosArray[0].vIsGeral
      const complemento = complementosArray.find(complemento => complemento.nomeGrupo === nomeGrupo);
      if (complemento) {
        return complemento.vIsGeral;
      }
    }

    return isGeral;
  };

  const validaIsObrigatorio = () => {
    if (disableEditNomeGrupo && complementosArray && complementosArray.length > 0) {
      // return complementosArray[0].vIsObrigatorio
      const complemento = complementosArray.find(complemento => complemento.nomeGrupo === nomeGrupo);
      if (complemento) {
        return complemento.vIsObrigatorio;
      }
    }

    return isObrigatorio;
  };

  const validaIsOpcional = () => {
    if (disableEditNomeGrupo && complementosArray && complementosArray.length > 0) {
      // return complementosArray[0].vIsOpcional
      const complemento = complementosArray.find(complemento => complemento.nomeGrupo === nomeGrupo);
      if (complemento) {
        return complemento.vIsOpcional;
      }
    }
    return isOpcional;
  };

  const validaLimiteOpcionais = () => {
    if (disableEditNomeGrupo && complementosArray && complementosArray.length > 0) {
      // return complementosArray[0].limiteOpcionais ? complementosArray[0].limiteOpcionais : 0;
      const complemento = complementosArray.find(complemento => complemento.nomeGrupo === nomeGrupo);
      if (complemento) {
        return complemento.vLimiteOpcionais ? complemento.vLimiteOpcionais : 0;
      }
    }
    return limiteOpcionais ? limiteOpcionais : 0;
  };

  const handleItemSelect = (produto) => {
    if (!itensSelected) {
      setItensSelected(itensSelected + ',')
      setNomeItensSelected(nomeItensSelected + ',')
    }
    if (itensSelected.includes(produto.idprodutos)) {
      setItensSelected(itensSelected.replace(produto.idprodutos + (produto.idprodutos.includes(",") ? "" : ','), ''));
      setNomeItensSelected(nomeItensSelected.replace(produto.nome + (produto.nome.includes(",") ? "" : ','), ''));
    } else {
      setItensSelected(itensSelected + produto.idprodutos + ',');
      setNomeItensSelected(nomeItensSelected + produto.nome + ',');
    }
  };

  const handleQtdSabores = (event) => {
    setQtdSabores(event.target.value);
  };

  const handleOpenStatus = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseStatus = () => {
    setAnchorEl(null);
  };

  const handleOpenEditOpc = (event, complemento) => {
    setAnchorElEditOpc(event.currentTarget);
    setSelectedComplemento(complemento);
  };

  const handleCloseEditOpc = () => {
    setAnchorElEditOpc(null);
    setSelectedComplemento(null);
  };

  const handleOpenEditGrupoOpc = (event, complemento) => {
    setAnchorElEditGrupoOpc(event.currentTarget);
    setSelectedComplemento(complemento);
  };

  const handleCloseEditGrupoOpc = () => {
    setAnchorElEditGrupoOpc(null);
    setSelectedComplemento(null);
  };

  const handleOpenEditItem = (event, complemento) => {
    event.stopPropagation()
    setAnchorElEditItem(event.currentTarget);
    setSelectedComplemento(complemento);
  };

  const handleCloseEditItem = (event) => {
    event.stopPropagation()
    setAnchorElEditItem(null);
    setSelectedComplemento(null);
  };

  const handleIsGeral = () => {
    setIsGeral(!isGeral)
  };

  const handleIsObrigatorio = () => {
    setIsObrigatorio(!isObrigatorio)
  };

  const handleIsOpcional = () => {
    setIsOpcional(!isOpcional)
  };

  const handleAddOutroComplemento = () => {
    setAdicionarOutroComplemento(!adicionarOutroComplemento)
  };

  const handlePossuiEstoque = () => {
    !possuiEstoque ? setEstoqueProduto(0) : setEstoqueProduto("")
    setPossuiEstoque(!possuiEstoque)
  };

  const handleAddCategoria = () => {
    setNovaCategoria("")
    setAddCategoria(true)
  };

  const handleNomeGrupo = (event) => {
    setNomeGrupo(event.target.value);
  };

  const handleNomeComplemento = (event) => {
    setNomeComplemento(event.target.value);
  };

  const handleDescricaoComplemento = (event) => {
    setDescricaoComplemento(event.target.value);
  };

  const handlePrecoComplemento = (event) => {
    setPrecoComplemento(maskReais(event.target.value));
  };

  const handleNomeProduto = (event) => {
    setNomeProduto(event.target.value);
  };

  const handleDescricaoProduto = (event) => {
    setDescricaoProduto(event.target.value);
  };

  const handlePreco = (event) => {
    setPrecoProduto(maskReais(event.target.value));
  };

  const handleDescontoProduto = (event) => {
    setDescontoProduto(maskReais(event.target.value));
  };

  const handleValorPontos = (event) => {
    setValorPontos(event.target.value);
  };

  const handleValorBroto = (event) => {
    setValorBroto(maskReais(event.target.value));
  };

  const handleEstoque = (tipo) => {
    switch (tipo) {
      case "add":
        estoqueProduto ? setEstoqueProduto(estoqueProduto + 1) : setEstoqueProduto(1)
        break;
      case "rm":
        estoqueProduto <= 0 ? setEstoqueProduto(0) : setEstoqueProduto(estoqueProduto - 1)
        break;
    }
  };

  const handleLimiteOpcionais = (tipo) => {
    switch (tipo) {
      case "add":
        limiteOpcionais ? setLimiteOpcionais(limiteOpcionais + 1) : setLimiteOpcionais(1)
        break;
      case "rm":
        limiteOpcionais <= 0 ? setLimiteOpcionais(0) : setLimiteOpcionais(limiteOpcionais - 1)
        break;
    }
  };

  const handleNovaCategoria = (event) => {
    setNovaCategoria(event.target.value);
  };

  const handleCancelarNovaCategoria = (event) => {
    setAddCategoria(false);
  };

  const handleAddNovaCategoria = () => {
    let categoriaAdicionar = novaCategoria
    const regex = /^\d+\s*-\s*/;
    if (!regex.test(novaCategoria)) {
      categoriaAdicionar = `${categorias ? categorias.length + 1 : 1} - ${novaCategoria}`;
    }
    categorias.push({
      "categoria": categoriaAdicionar
    })
    setAddCategoria(false);
  };

  useEffect(() => {

    const urlBase = Cookies.get('UBs');
    setUrlBase(urlBase)
    getCategorias(urlBase);
    getProdutos(urlBase);
  }, []);

  const getProdutos = (url) => {
    axios.post(`${url}/produtos.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let responseOrigin = response.data
        response = response.data

        if (response) {
          // ajusta as categorias
          let ultimaCategoria = "", ultimaSubcategoria = "", ultimoProduto = "", arrExpanded = []
          for (const produto of response) {
            const partes = produto.categoria.split(' - ');
            const categoriaNumero = partes[0];
            const categoriaOriginal = partes[1];
            const categoriaProduto = partes.slice(2).join(' - ');
            produto.categoriaNumero = categoriaNumero
            produto.categoriaOriginal = categoriaOriginal
            produto.categoriaProduto = categoriaProduto
            produto.subcategoriaOriginal = produto.subcategoria
            arrExpanded[categoriaOriginal] = false
            if (ultimaCategoria == "" || ultimaCategoria != produto.categoria) {
              if (!produto.tipo) {
                if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                  ultimaCategoria = produto.categoria
                  ultimaSubcategoria = produto.subcategoria
                  ultimoProduto = categoriaProduto
                  produto.isFirst = "true"
                  continue
                } else {
                  if (produto.subcategoria == "") {
                    ultimaSubcategoria = produto.subcategoria
                  }
                  ultimaCategoria = produto.categoria
                  ultimoProduto = categoriaProduto
                  produto.isFirst = "true"
                  continue
                }
              } else {
                if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                  ultimaCategoria = produto.categoria
                  ultimaSubcategoria = produto.subcategoria
                  ultimoProduto = categoriaProduto
                  produto.isFirst = "true"
                  continue
                }
              }
            } else {
              if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                ultimaCategoria = produto.categoria
                ultimaSubcategoria = produto.subcategoria
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              }
            }

            if (produto.tipo) {
              if (categoriaProduto !== ultimoProduto) {
                produto.isFirst = "true"
              }
            }
            ultimoProduto = categoriaProduto

            produto.categoria = ""
            produto.subcategoria = ""
          }
          // setExpandedArr(arrExpanded)
          // handleCloseLoading();
          setItems(response);
          setItemsOrigim(response);
        }
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  }

  const getCategorias = (url) => {
    axios.post(`${url}/categorias.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        if (response.data) {
          setCategorias(response.data)
        }
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  }

  const handleUpdateAllStatus = (status) => {
    handleOpenStatus(false)
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/alterar-todos-status.php`;
    const json = JSON.stringify({
      idprodutos: itensSelected.replace(/,+$/, ''),
      status: status === "ativar" ? "A" : "I"
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso") {
            getCategorias(urlBase)
            getProdutos(urlBase)
            setItensSelected("")
            update("", `${Date.now()}___success___Seus itens foram atualizados com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel atualizar seus itens. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleDeletarSelecionados = (status) => {
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/deletar-todos-produtos.php`;
    const json = JSON.stringify({
      idprodutos: itensSelected.replace(/,+$/, ''),
      nomesprodutos: nomeItensSelected.replace(/,+$/, ''),
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso") {
            getCategorias(urlBase)
            getProdutos(urlBase)
            setItensSelected("")
            update("", `${Date.now()}___success___Seus itens foram deletados com sucesso.`)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel deletar seus itens. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleAdicionarProduto = async () => {

    if (itemSelected) {
      handleAtualizarProduto()
      return
    }

    if (!categoriaProduto) {
      update("", `${Date.now()}___error___Você precisa selecionar uma categoria para adicionar.`)
      return
    }
    if (!nomeProduto) {
      update("", `${Date.now()}___error___O nome do produto precisa ser preenchido.`)
      return
    }
    if (!descricaoProduto) {
      update("", `${Date.now()}___error___A descrição do produto precisa ser preenchida.`)
      return
    }
    if (!precoProduto) {
      update("", `${Date.now()}___error___O preço do produto precisa ser preenchido.`)
      return
    }

    let desconto = "", estoque = "NULL", valorpontos = "", valorbroto = "", unitario = "Por pessoa"
    if (aplicarDesconto) {
      desconto = descontoProduto
    }
    if (possuiEstoque) {
      estoque = estoqueProduto
    }
    if (habilitaResgate) {
      valorpontos = valorPontos
    }
    if (isPizza) {
      valorbroto = valorBroto
    }
    if (habilitaUnitario) {
      unitario = "Unitário"
    }

    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/cadastro-produto.php`;
    const formData = new FormData();
    formData.append('idestabelecimentos', idestabelecimentos);
    formData.append('nome', nomeProduto);
    formData.append('descricao', descricaoProduto);
    formData.append('categoria', categoriaProduto);
    formData.append('subcategoria', "");
    formData.append('valor', precoProduto);
    formData.append('valor_broto', valorbroto);
    formData.append('valor_desconto', desconto);
    formData.append('valor_pontos', valorpontos);
    formData.append('tipovalor', unitario);
    formData.append('obs', isPizza ? qtdSabores : "");
    formData.append('estoque', estoque);
    formData.append('opcionais', JSON.stringify(complementosArray));

    filesImagens.forEach((image, index) => {
      if (image) {
        formData.append(`file-${index}`, image);
      }
    });

    try {
      const resp = await axios.post(post_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = resp.data
      if (response) {
        if (response.resposta === "sucesso") {
          getCategorias(urlBase)
          getProdutos(urlBase)
          limpaAdicionar()
          setOpenModalProduto(false)
          update("", `${Date.now()}___success___Seu item foi cadastrado com sucesso.`)
          return
        }
      }
      update("", `${Date.now()}___error___Não foi possivel cadastrar seu item. Por favor verifique sua conexão e tente novamente.`)
    } catch (error) {
      update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
    }
  };


  const handleAtualizarProduto = async () => {

    if (!categoriaProduto) {
      update("", `${Date.now()}___error___Você precisa selecionar uma categoria para atualizar.`)
      return
    }
    if (!nomeProduto) {
      update("", `${Date.now()}___error___O nome do produto precisa ser preenchido.`)
      return
    }
    if (!descricaoProduto) {
      update("", `${Date.now()}___error___A descrição do produto precisa ser preenchida.`)
      return
    }
    if (!precoProduto) {
      update("", `${Date.now()}___error___O preço do produto precisa ser preenchido.`)
      return
    }

    let desconto = "", estoque = "NULL", valorpontos = "", valorbroto = "", unitario = "Por pessoa"
    if (aplicarDesconto) {
      desconto = descontoProduto
    }
    if (possuiEstoque) {
      estoque = estoqueProduto
    }
    if (habilitaResgate) {
      valorpontos = valorPontos
    }
    if (isPizza) {
      valorbroto = valorBroto
    }
    if (habilitaUnitario) {
      unitario = "Unitário"
    }

    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/editar-produto.php`;
    const formData = new FormData();
    formData.append('idestabelecimentos', idestabelecimentos);
    formData.append('idprodutos', itemSelected.idprodutos);
    formData.append('nome', nomeProduto);
    formData.append('descricao', descricaoProduto);
    formData.append('categoria', categoriaProduto);
    formData.append('valor', precoProduto);
    formData.append('valor_broto', valorbroto);
    formData.append('valor_desconto', desconto);
    formData.append('valor_pontos', valorpontos);
    formData.append('tipovalor', unitario);
    formData.append('obs', isPizza ? qtdSabores : "");
    formData.append('estoque', estoque);
    formData.append('opcionais', JSON.stringify(complementosArray));

    filesImagens.forEach((image, index) => {
      if (image) {
        formData.append(`file-${index}`, image);
      }
    });

    try {
      const resp = await axios.post(post_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = resp.data
      if (response) {
        if (response.resposta === "sucesso") {
          getCategorias(urlBase)
          getProdutos(urlBase)
          limpaAdicionar()
          setOpenModalProduto(false)
          update("", `${Date.now()}___success___Seu item foi atualizado com sucesso.`)
          return
        }
      }
      update("", `${Date.now()}___error___Não foi possivel cadastrar seu item. Por favor verifique sua conexão e tente novamente.`)
    } catch (error) {
      update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`)
    }
  };

  const limpaAdicionar = () => {
    setFilesImagens([])
    setImagensProduto([])
    setCategoriaProduto("")
    setNomeGrupo("")
    setNomeProduto("")
    setDescricaoProduto("")
    setHabilitaUnitario(true)
    setPrecoProduto("")
    setValorPontos("")
    setHabilitaResgate(false)
    setAplicarDesconto(false)
    setValorBroto("")
    setDescontoProduto("")
    setEstoqueProduto("")
    setPossuiEstoque(false)
    setComplementosArray([])
  }

  const handleAdicionarComplemento = () => {

    let vIsObrigatorio = validaIsObrigatorio()
    let vIsGeral = validaIsGeral()
    let vLimiteOpcionais = validaLimiteOpcionais()
    let vIsOpcional = validaIsOpcional()

    if (isEditingComplemento) {
      if (!nomeComplemento && nomeComplemento.length < 5) {
        update("", `${Date.now()}___error___O nome do complemento precisa ser preenchido.`)
        return;
      }

      if (!precoComplemento) {
        update("", `${Date.now()}___error___O preço do complemento precisa ser preenchido.`)
        return;
      }

      setComplementosArray(prevArray => {
        const novoArray = [...prevArray];
        if (indexEdit >= 0 && indexEdit < novoArray.length) {
          novoArray[indexEdit] = {
            ...novoArray[indexEdit],
            nomeComplemento: nomeComplemento,
            descricaoComplemento: descricaoComplemento,
            precoComplemento: precoComplemento
          };
        }

        return novoArray;
      });

      setIsEditingComplemento(false)
      setOpenModalComplemento(false)
      setNomeComplemento("")
      setDescricaoComplemento("")
      setPrecoComplemento("")
      return
    }

    if (isEditingGrupo) {
      if (!nomeGrupo && nomeGrupo.length < 5) {
        update("", `${Date.now()}___error___O nome do grupo precisa ser preenchido.`)
        return
      }

      const novosDados = {
        nomeGrupo: nomeGrupo,
        vIsOpcional: vIsOpcional,
        vIsGeral: vIsGeral,
        vIsObrigatorio: vIsObrigatorio,
        vLimiteOpcionais: vLimiteOpcionais
      };

      setComplementosArray(prevArray =>
        prevArray.map(complemento =>
          complemento.nomeGrupo === grupoOriginal
            ? { ...complemento, ...novosDados }
            : complemento
        )
      );

      setIsEditingGrupo(false)
      setDisableEditNomeGrupo(true)
      setOpenModalComplemento(false)
      return
    }

    if (!nomeGrupo && nomeGrupo.length < 5) {
      update("", `${Date.now()}___error___O nome do grupo precisa ser preenchido.`)
      return;
    }

    if (!nomeComplemento && nomeComplemento.length < 5) {
      update("", `${Date.now()}___error___O nome do complemento precisa ser preenchido.`)
      return;
    }

    if (!precoComplemento) {
      update("", `${Date.now()}___error___O preço do complemento precisa ser preenchido.`)
      return;
    }

    const optionObject = {
      nomeGrupo,
      vLimiteOpcionais,
      vIsGeral,
      vIsObrigatorio,
      nomeComplemento,
      descricaoComplemento,
      precoComplemento,
      vIsOpcional,
      adicionarOutroComplemento
    };
    setComplementosArray(prevArray => [...prevArray, optionObject]);
    setNomeComplemento("")
    setPrecoComplemento("")
    setDescricaoComplemento("")
    if (!adicionarOutroComplemento) {
      setAbaComplementos(0)
      setNomeGrupo("")
      setDisableEditNomeGrupo(false)
      handleCloseModalComplemento();
      return
    }
    setDisableEditNomeGrupo(true)
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      const fileType = file.type;
      if (fileType !== "text/csv") {
        update("", `${Date.now()}___error___Somente arquivos CSV são permitidos.`)
        fileInputRef.current.value = null;
        setFileImport({ name: '', size: '' });
        setFileImportar(null);
        return;
      }

      const maxSize = 3000 * 1024 * 1024;
      if (file.size > maxSize) {
        update("", `${Date.now()}___error___O arquivo deve ter no máximo 300MB.`)
        fileInputRef.current.value = null;
        setFileImport({ name: '', size: '' });
        setFileImportar(null);
        return;
      }

      setFileImportar(file);
      setFileImport({
        name: file.name,
        size: (file.size / 1024).toFixed(2) + ' KB' // Converte o tamanho para KB e formata
      });
    }
  };

  const handleImportarCsv = async () => {
    setImportando(true)
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    const post_url = `${urlBase}/import-cardapio.php`;
    const formData = new FormData();
    formData.append('idestabelecimentos', idestabelecimentos);
    formData.append('cardapiocsv', fileImportar);

    try {
      const response = await axios.post(post_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentage = Math.round((current / total) * 100);
          setUploadProgress(percentage);
        }
      });

      if (response.data.resposta === "sucesso") {
        getCategorias(urlBase)
        getProdutos(urlBase)
        setImportando(false)
        setUploadProgress(0)
        handleCloseModalProduto()
        update("", `${Date.now()}___success___Seu cardápio foi importado com sucesso.`);
      } else {
        setImportando(false)
        update("", `${Date.now()}___error___Não foi possível importar seu cardápio. Por favor, verifique sua conexão e tente novamente.`);
      }
    } catch (error) {
      setImportando(false)
      update("", `${Date.now()}___error___Não foi possível conectar ao servidor, verifique sua conexão e tente novamente.`);
    }
  };

  const totalItens = (it) => {
    const arrCount = itemsOrigim.filter(item => item.categoriaOriginal === it && !item.tipo)
    return <>{arrCount.length > 1 ? <>{arrCount.length} itens</> : <>{arrCount.length} item</>}</>
  }

  const initialFilesImagens = [...filesImagens];
  const initialImagensProduto = [...imagensProduto];

  const urlToFile = async (url, filename, mimeType) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  async function imageExists(image_url) {
    try {
      const response = await axios.get(image_url, { method: 'HEAD' });
      if (response.data && response.data.includes('<html')) {
        return false
      }
      return response.status === 200;
    } catch (error) {
      return false;
    }
  }

  const checkAndSetImage = async (id, index) => {
    const url = `${urlBase.replaceAll("/api", "")}/imagens-produtos/${id}_${index}.png?${new Date().getTime()}`;

    if (await imageExists(url)) {
      initialImagensProduto[index] = url;
      const file = await urlToFile(url, `${id}_${index}.png?${new Date().getTime()}`, 'image/png');
      initialFilesImagens[index] = file;
    } else {
      initialFilesImagens[index] = null;
      initialImagensProduto[index] = null;
    }
  };

  const checkImages = async (id) => {
    for (let i = 0; i < 5; i++) {
      await checkAndSetImage(id, i);
    }
    setFilesImagens(initialFilesImagens);
    setImagensProduto(initialImagensProduto);
  };


  const handleItem = (produto) => {
    checkImages(produto.idprodutos);
    setItemSelected(produto)
    setOpenModalProduto(true)
    setImport(false)
    setCategoriaProduto(`${produto.categoriaNumero} - ${produto.categoriaOriginal}`)
    setNomeProduto(produto.nome)
    setQtdSabores(produto.obs ? produto.obs : (produto.categoriaOriginal.toLowerCase().includes("pizza") ? "2" : ""))
    setDescricaoProduto(produto.descricao)
    setHabilitaUnitario(produto.tipovalor === "Unitário" ? true : false)
    setPrecoProduto(produto.valor)

    setHabilitaResgate(produto.valor_pontos ? true : false)
    produto.valor_pontos ? setValorPontos(produto.valor_pontos) : setValorPontos("")

    setAplicarDesconto(produto.valor_desconto ? true : false)
    produto.valor_desconto ? setDescontoProduto(produto.valor_desconto) : setDescontoProduto("")

    setPossuiEstoque(produto.estoque ? true : false)
    produto.estoque ? setEstoqueProduto(produto.estoque) : setEstoqueProduto("")

    if (produto.categoriaOriginal.toLowerCase().includes("pizza")) {
      setIsPizza(true)
      setValorBroto(produto.valor_broto)
    } else {
      setIsPizza(false)
      setValorBroto("")
    }

    setComplementosArray([]);
    for (const item of items) {
      if (item.tipo && ((item.categoriaOriginal === produto.categoriaOriginal && item.categoriaProduto === produto.nome) || (item.categoriaOriginal === produto.categoriaOriginal && !item.categoriaProduto))) {

        let nomeGrupo = item.subcategoriaOriginal, vLimiteOpcionais = item.limite, vIsObrigatorio = item.tipo.includes("true"), vIsGeral = !item.categoriaProduto
        let nomeComplemento = item.nome, descricaoComplemento = item.descricao, precoComplemento = item.valor
        let vIsOpcional = item.tipo.includes("opcionais"), adicionarOutroComplemento = false, idProduto = item.idprodutos
        const optionObject = {
          idProduto,
          nomeGrupo,
          vLimiteOpcionais,
          vIsGeral,
          vIsObrigatorio,
          nomeComplemento,
          descricaoComplemento,
          precoComplemento,
          vIsOpcional,
          adicionarOutroComplemento
        };
        setComplementosArray(prevArray => [...prevArray, optionObject]);
      }
    }
  }

  const handleOpt = (opcao, produto, index) => {
    handleCloseEditOpc()
    switch (opcao) {
      case "duplicar":
        const produtoNovo = { ...produto, idProduto: "" };
        setComplementosArray(prevArray => [...prevArray, produtoNovo]);
        break;
      case "editar":
        setIsEditingComplemento(true)
        setIndexEdit(index)
        setIsEditingGrupo(false)
        setNomeComplemento(produto.nomeComplemento)
        setDescricaoComplemento(produto.descricaoComplemento)
        setPrecoComplemento(produto.precoComplemento)
        setDisableEditNomeGrupo(true)
        setAbaComplementos(1)
        setOpenModalComplemento(true)
        setGrupoOriginal(produto.nomeGrupo)
        setNovoGrupo(false)
        break;
      case "excluir":
        setComplementosArray(prevArray => prevArray.filter(complemento => complemento.idProduto !== produto.idProduto));
        break;
    }
  }

  const handleOptItem = (event, opcao, produto) => {
    handleCloseEditItem(event)
    const urlBase = Cookies.get('UBs');
    const idestabelecimentos = Cookies.get('iEst');
    let post_url, json
    switch (opcao) {
      case "status":
        post_url = `${urlBase}/alterar-todos-status.php`;
        json = JSON.stringify({
          idprodutos: produto.idprodutos,
          status: produto.status === "I" ? "A" : "I"
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            if (response) {
              if (response.resposta === "sucesso") {
                getCategorias(urlBase)
                getProdutos(urlBase)
                setItensSelected("")
                update("", `${Date.now()}___success___Seu item foi atualizado com sucesso.`)
                return
              }
            }
            update("", `${Date.now()}___error___Não foi possivel atualizar seu item. Por favor verifique sua conexão e tente novamente.`)
          })
          .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
        break;
      case "excluir":
        post_url = `${urlBase}/deletar-todos-produtos.php`;
        json = JSON.stringify({
          idprodutos: produto.idprodutos,
          nomesprodutos: produto.nome,
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            if (response) {
              if (response.resposta === "sucesso") {
                getCategorias(urlBase)
                getProdutos(urlBase)
                setItensSelected("")
                update("", `${Date.now()}___success___Seus item foi deletado com sucesso.`)
                return
              }
            }
            update("", `${Date.now()}___error___Não foi possivel deletar seu item. Por favor verifique sua conexão e tente novamente.`)
          })
          .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
        break;
      case "destaque":
        const destaque = produto.destaque === "S" ? "N" : "S"
        post_url = `${urlBase}/editar-destaques.php`;
        json = JSON.stringify({
          idprodutos: produto.idprodutos,
          destaque: destaque,
        });

        axios.post(post_url, json)
          .then(resp => {
            const response = resp.data
            if (response) {
              if (response.resposta === "sucesso") {
                getCategorias(urlBase)
                getProdutos(urlBase)
                setItensSelected("")
                update("", `${Date.now()}___success___Seu item foi destacado com sucesso.`)
                return
              }
            }
            update("", `${Date.now()}___error___Não foi possivel destacar seu item. Por favor verifique sua conexão e tente novamente.`)
          })
          .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
        break;
    }
  }

  const handleOptGrupo = (opcao, grupo) => {
    handleCloseEditGrupoOpc()
    switch (opcao) {
      case "editar":
        console.log(grupo)
        if (grupo) {
          setNomeGrupo(grupo.nomeGrupo)
        }
        setIsEditingGrupo(true)
        setIsEditingComplemento(false)
        setNomeComplemento("")
        setDescricaoComplemento("")
        setPrecoComplemento("")
        setDisableEditNomeGrupo(false)
        setAbaComplementos(0)
        setOpenModalComplemento(true)
        setGrupoOriginal(grupo.nomeGrupo)
        setNovoGrupo(false)
        break;
      case "excluir":
        setComplementosArray(prevArray => prevArray.filter(complemento => complemento.nomeGrupo !== grupo.nomeGrupo));
        break;
    }
  }

  const renderComplemento = (complemento, index) => (
    <React.Fragment key={complemento.nomeComplemento}>
      <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
        <Typography style={{ width: "100%", lineHeight: "1.2" }}>
          <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{complemento.nomeComplemento}</b><br />
          {complemento.descricaoComplemento && (
            <>
              <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: complemento.descricaoComplemento.replaceAll("\n", "<br/>") }}></b><br />
            </>
          )}
          <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {complemento.precoComplemento}</b>
        </Typography>
        {!complemento.vIsOpcional && (
          <div style={{ position: "absolute", right: "120px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "80px", height: "40px", display: "flex", alignItems: "center" }}>
            <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", fontSize: "12px" }}>1</p>
            <RemoveOutlined style={{ position: "absolute", left: "8px", color: "#464545", fontSize: "14px" }} />
            <AddOutlined style={{ position: "absolute", right: "8px", color: "#464545", fontSize: "14px" }} />
          </div>
        )}
        {complemento.vIsOpcional && <Radio checked />}
        <MoreVert style={{ color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditOpc(event, complemento)} />
        <Menu id="opcoes2" anchorEl={anchorElEditOpc} open={Boolean(anchorElEditOpc && selectedComplemento === complemento)} onClose={handleCloseEditOpc}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{ marginTop: 1 }}
        >

          <List style={{ width: 200, fontSize: 16 }}>
            <ListItem button onClick={() => handleOpt("duplicar", complemento)}>
              <ContentCopyOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Duplicar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button onClick={() => handleOpt("editar", complemento, index)}>
              <EditOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Editar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button onClick={() => handleOpt("excluir", complemento, index)}>
              <DeleteOutline style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Excluir" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
          </List>
        </Menu>
      </Typography>
      <div style={{ height: 16 }} />
      <Divider />
      <div style={{ height: 16 }} />
    </React.Fragment>
  );

  const renderGrupo = (grupo, complementosEl) => (
    <React.Fragment key={grupo.nomeGrupo}>
      <div style={{ border: '1px #DCDBDB solid', borderRadius: 8, background: "#fff" }}>
        <div style={{ display: "flex", alignItems: "center", background: "#F2F2F2", padding: 16, borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottom: '1px #DCDBDB solid' }}>
          <Typography style={{ fontSize: 14, color: "#999" }}>
            <b style={{ fontSize: 16, color: "#1B1B1B" }}>{grupo.nomeGrupo}</b><br />
            {grupo.vLimiteOpcionais ? (grupo.vLimiteOpcionais > 1 ? `Escolha até ${grupo.vLimiteOpcionais} opções` : `Escolha até ${grupo.vLimiteOpcionais} opção`) : ""}
          </Typography>
          {grupo.vIsObrigatorio &&
            <Typography style={{ marginLeft: "auto", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
              OBRIGATÓRIO
            </Typography>
          }
          <div style={{ width: 10 }} />
          <MoreVert style={{ marginLeft: !grupo.vIsObrigatorio ? "auto" : "", color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditGrupoOpc(event, grupo)} />
          <Menu id="opcoes" anchorEl={anchorElEditGrupoOpc} open={Boolean(anchorElEditGrupoOpc && selectedComplemento === grupo)} onClose={handleCloseEditGrupoOpc}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{ marginTop: 1 }}
          >

            <List style={{ width: 200, fontSize: 16 }}>
              <ListItem button onClick={() => handleOptGrupo("editar", grupo)}>
                <EditOutlined style={{ marginRight: 14, color: "#464545" }} />
                <ListItemText primary="Editar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
              </ListItem>
              <ListItem button onClick={() => handleOptGrupo("excluir", grupo)}>
                <DeleteOutline style={{ marginRight: 14, color: "#464545" }} />
                <ListItemText primary="Excluir" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
              </ListItem>
            </List>
          </Menu>
        </div>
        <div style={{ padding: 16 }}>
          {complementosEl}
        </div>
        <div style={{ padding: 16, marginTop: -30 }}>
          <Button style={{ textTransform: "none" }} fullWidth startIcon={<Add />} onClick={() => handleEditarGrupoComplementos(grupo)}>Novo complemento</Button>
        </div>
      </div>
      <div style={{ height: 16 }} />
    </React.Fragment>
  );

  const gerarComplementos = (complementosArr) => {
    // Ordena os complementos pelo nome do grupo
    const sortedComplementosArr = complementosArr.sort((a, b) => a.nomeGrupo.localeCompare(b.nomeGrupo));

    let complementos = [];
    let complementosEl = [];
    let lastGrupo = "";

    sortedComplementosArr.forEach((complemento, index) => {
      if (complemento.nomeGrupo === lastGrupo || lastGrupo === "") {
        complementosEl.push(renderComplemento(complemento, index));
      } else {
        complementos.push(renderGrupo(sortedComplementosArr[index - 1], complementosEl));
        complementosEl = [renderComplemento(complemento, index)];
      }

      if (sortedComplementosArr.length === index + 1) {
        complementos.push(renderGrupo(complemento, complementosEl));
      }
      lastGrupo = complemento.nomeGrupo;
    });

    return complementos;
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <Dialog
        open={openModalComplemento}
        onClose={handleCloseModalComplemento}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 950 } }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
          {!novoGrupo ? "Atualizar grupo" : "Criar grupo"}
          <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalComplemento}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <Divider />
        <Tabs value={abaComplementos} onChange={handleChangeComplementos} style={{ margin: 16, borderBottom: "solid 1px #e0e0e0" }} allowScrollButtonsMobile variant="standard" scrollButtons="auto">
          <Tab label="Grupo" {...a11yProps(0)} style={{ textTransform: "none" }} />
          <Tab label="Complementos" {...a11yProps(1)} style={{ textTransform: "none" }} />
        </Tabs>
        {abaComplementos === 0 && // Aba grupo
          <>
            <Grid container spacing={2} style={{ padding: 24 }}>
              <Grid item xs={12} sm={12} md={6} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", paddingRight: isMobile ? 0 : 24, paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Nome do grupo <b style={{ color: "#A51D1D" }}>*</b>
                </Typography>
                <div style={{ height: 4 }}></div>
                <TextField
                  fullWidth
                  value={nomeGrupo}
                  onChange={handleNomeGrupo}
                  variant="outlined"
                  size="large"
                  style={{ pointerEvents: disableEditNomeGrupo ? 'none' : 'auto', opacity: disableEditNomeGrupo ? 0.5 : 1 }}
                  placeholder="Insira o nome do grupo"
                  type="text"
                />
                <div style={{ height: 24 }} />
                <Typography style={{ display: "flex", alignItems: "center", textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  <b>Quantidade máxima</b>
                  <div style={{ position: "relative", pointerEvents: disableEditNomeGrupo ? 'none' : 'auto', opacity: disableEditNomeGrupo ? 0.5 : 1, marginLeft: "auto", right: "0px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", maxWidth: 131, minWidth: 131, height: "40px", display: "flex", alignItems: "center" }}>
                    <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{validaLimiteOpcionais()}</p>
                    <RemoveOutlined onClick={() => handleLimiteOpcionais("rm")} style={{ position: "absolute", left: "16px", color: "#464545", cursor: "pointer" }} />
                    <AddOutlined onClick={() => handleLimiteOpcionais("add")} style={{ position: "absolute", right: "16px", color: "#464545", cursor: "pointer" }} />
                  </div>
                </Typography>
                <div style={{ height: 24 }} />
                <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                  <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600", alignItems: "center" }}>
                    <b>Este complemento é obrigatório?</b>
                    <FormControlLabel style={{ marginLeft: "auto", pointerEvents: disableEditNomeGrupo ? 'none' : 'auto', opacity: disableEditNomeGrupo ? 0.5 : 1 }}
                      control={<MaterialUISwitch sx={{ m: 1 }} checked={validaIsObrigatorio()} onChange={handleIsObrigatorio} />}
                    />
                  </Typography>
                </div>
                <div style={{ height: 24 }} />
                <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                  <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600", alignItems: "center" }}>
                    <b>Utilizar em todos os itens da categoria?</b>
                    <FormControlLabel style={{ marginLeft: "auto", pointerEvents: disableEditNomeGrupo ? 'none' : 'auto', opacity: disableEditNomeGrupo ? 0.5 : 1 }}
                      control={<MaterialUISwitch sx={{ m: 1 }} checked={validaIsGeral()} onChange={handleIsGeral} />}
                    />
                  </Typography>
                </div>
                <div style={{ height: 24 }} />
                <div style={{
                  width: "100%", display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  color: "#464545",
                  fontSize: 14,
                  border: "solid 2px #1B78F8",
                  borderRadius: 8,
                  padding: 16,
                  background: "#ECF0F9",
                }}>
                  <ErrorOutline style={{ color: "#1B78F8" }} />
                  <div style={{ width: 16 }} />
                  <b>Para salvar preencha as informações</b>
                </div>
              </Grid>


              <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>
                {isMobile && <div style={{ height: 16 }} />}

                <Typography style={{ background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <RemoveRedEyeOutlined />
                    <div style={{ width: 10 }} />
                    Visualizar prévia
                  </Typography>
                  <div style={{ height: 18 }} />
                  <div style={{ border: '1px #DCDBDB solid', borderRadius: 8, background: "#fff" }}>
                    <div style={{ display: "flex", alignItems: "center", background: "#F2F2F2", padding: 16, borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottom: '1px #DCDBDB solid' }}>
                      <Typography style={{ fontSize: 14, color: "#999" }}>
                        <b style={{ fontSize: 16, color: "#1B1B1B" }}>{nomeGrupo ? nomeGrupo : "[Sem nome]"}</b><br />
                        {validaLimiteOpcionais() ? (validaLimiteOpcionais() > 1 ? `Escolha até ${validaLimiteOpcionais()} opções` : `Escolha até ${validaLimiteOpcionais()} opção`) : ""}
                      </Typography>
                      {validaIsObrigatorio() &&
                        <Typography style={{ marginLeft: "auto", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                          OBRIGATÓRIO
                        </Typography>
                      }
                      <div style={{ width: 10 }} />
                      <MoreVert style={{ marginLeft: validaIsObrigatorio() ? "" : "auto", color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditGrupoOpc(event)} />
                    </div>

                    <div style={{ padding: 16 }}>

                      {complementosArray && complementosArray.map((complemento, index) => (
                        (nomeGrupo === complemento.nomeGrupo && novoGrupo) || (grupoOriginal === complemento.nomeGrupo && !novoGrupo) &&
                        <>
                          <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                            <Typography style={{ width: "100%", lineHeight: "1.2" }}>
                              <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{complemento.nomeComplemento}</b><br />
                              {complemento.descricaoComplemento && (
                                <>
                                  <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: complemento.descricaoComplemento.replaceAll("\n", "<br/>") }}></b><br />
                                </>
                              )}
                              <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {complemento.precoComplemento}</b>
                            </Typography>
                            {!complemento.vIsOpcional && (
                              <>
                                <div style={{ position: "absolute", right: "90px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "80px", height: "40px", display: "flex", alignItems: "center" }}>
                                  <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", fontSize: "12px" }}>1</p>
                                  <RemoveOutlined style={{ position: "absolute", left: "8px", color: "#464545", fontSize: "14px" }} />
                                  <AddOutlined style={{ position: "absolute", right: "8px", color: "#464545", fontSize: "14px" }} />
                                </div>
                              </>
                            )}
                            {complemento.vIsOpcional && (
                              <Radio
                                checked
                              />
                            )}
                            <MoreVert style={{ color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditOpc(event, complemento)} />
                            <Menu id="opcoes" anchorEl={anchorElEditOpc} open={Boolean(anchorElEditOpc && selectedComplemento === complemento)} onClose={handleCloseEditOpc}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              sx={{ marginTop: 1 }}
                            >

                              <List style={{ width: 200, fontSize: 16 }}>
                                <ListItem button onClick={() => handleOpt("duplicar", complemento)}>
                                  <ContentCopyOutlined style={{ marginRight: 14, color: "#464545" }} />
                                  <ListItemText primary="Duplicar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                </ListItem>
                                <ListItem button onClick={() => handleOpt("editar", complemento, index)}>
                                  <EditOutlined style={{ marginRight: 14, color: "#464545" }} />
                                  <ListItemText primary="Editar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                </ListItem>
                                <ListItem button onClick={() => handleOpt("excluir", complemento, index)}>
                                  <DeleteOutline style={{ marginRight: 14, color: "#464545" }} />
                                  <ListItemText primary="Excluir" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                </ListItem>
                              </List>
                            </Menu>
                          </Typography>
                          <div style={{ height: 16 }} />
                          <Divider />
                          <div style={{ height: 16 }} />
                        </>
                      ))}
                      {!isEditingComplemento && nomeComplemento ?
                        <>
                          <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                            <Typography style={{ width: "100%", lineHeight: "1.2" }}>
                              <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{nomeComplemento}</b><br />
                              {descricaoComplemento && (
                                <>
                                  <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: descricaoComplemento.replaceAll("\n", "<br/>") }}></b><br />
                                </>
                              )}
                              <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {precoComplemento}</b>
                            </Typography>
                            {!validaIsOpcional() && (
                              <>
                                <div style={{ position: "absolute", right: "56px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "80px", height: "40px", display: "flex", alignItems: "center" }}>
                                  <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", fontSize: "12px" }}>1</p>
                                  <RemoveOutlined style={{ position: "absolute", left: "8px", color: "#464545", fontSize: "14px" }} />
                                  <AddOutlined style={{ position: "absolute", right: "8px", color: "#464545", fontSize: "14px" }} />
                                </div>
                              </>
                            )}
                            {validaIsOpcional() && (
                              <Radio
                                checked
                              />
                            )}
                          </Typography>
                        </>
                        :
                        <Typography style={{ color: "#DCDBDB", fontSize: 14 }}>
                          Sem complementos
                        </Typography>
                      }
                    </div>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </>
        }

        {abaComplementos === 1 && // Aba complementos
          <>
            <Grid container spacing={2} style={{ padding: 24 }}>
              <Grid item xs={12} sm={12} md={6} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", paddingRight: isMobile ? 0 : 24, paddingTop: 0 }}>
                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Nome do complemento <b style={{ color: "#A51D1D" }}>*</b>
                </Typography>
                <div style={{ height: 4 }}></div>
                <TextField
                  fullWidth
                  value={nomeComplemento}
                  onChange={handleNomeComplemento}
                  variant="outlined"
                  size="large"
                  placeholder="Insira o nome do complemento"
                  type="text"
                />
                <div style={{ height: 16 }} />

                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Descrição
                </Typography>
                <div style={{ height: 4 }}></div>
                <TextField
                  fullWidth
                  value={descricaoComplemento}
                  onChange={handleDescricaoComplemento}
                  variant="outlined"
                  size="large"
                  placeholder="Insira a descrição do complemento"
                  type="text"
                />

                <div style={{ height: 16 }} />

                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Preço <b style={{ color: "#A51D1D" }}>*</b>
                </Typography>
                <div style={{ height: 4 }}></div>
                <TextField
                  fullWidth
                  value={precoComplemento}
                  onChange={handlePrecoComplemento}
                  variant="outlined"
                  size="large"
                  placeholder="Insira o preço do complemento"
                  type="text"
                />

                <div style={{ height: 16 }} />

                <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                  <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600", alignItems: "center" }}>
                    <b>Este item é opcional?</b>
                    <FormControlLabel style={{ marginLeft: "auto", pointerEvents: disableEditNomeGrupo ? 'none' : 'auto', opacity: disableEditNomeGrupo ? 0.5 : 1 }}
                      control={<MaterialUISwitch sx={{ m: 1 }} checked={validaIsOpcional()} onChange={handleIsOpcional} />}
                    />
                  </Typography>
                </div>

                <FormGroup style={{ marginRight: "auto", marginLeft: 16 }}>
                  <FormControlLabel control={<Checkbox checked={adicionarOutroComplemento} onChange={handleAddOutroComplemento} />} label={<Typography style={{ fontSize: 14 }}>&nbsp;&nbsp;&nbsp;Adicionar outro</Typography>} />
                </FormGroup>
              </Grid>


              <Grid item xs={12} sm={12} md={6} style={{ paddingTop: 0 }}>

                <Typography style={{ background: "#FFF5E2", padding: 16, borderRadius: 8 }}>
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <RemoveRedEyeOutlined />
                    <div style={{ width: 10 }} />
                    Visualizar prévia
                  </Typography>
                  <div style={{ height: 18 }} />
                  <div style={{ border: '1px #DCDBDB solid', borderRadius: 8, background: "#fff" }}>
                    <div style={{ display: "flex", alignItems: "center", background: "#F2F2F2", padding: 16, borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottom: '1px #DCDBDB solid' }}>
                      <Typography style={{ fontSize: 14, color: "#999" }}>
                        <b style={{ fontSize: 16, color: "#1B1B1B" }}>{nomeGrupo ? nomeGrupo : "[Sem nome]"}</b><br />
                        {validaLimiteOpcionais() ? (validaLimiteOpcionais() > 1 ? `Escolha até ${validaLimiteOpcionais()} opções` : `Escolha até ${validaLimiteOpcionais()} opção`) : ""}
                      </Typography>
                      {validaIsObrigatorio() &&
                        <Typography style={{ marginLeft: "auto", fontSize: "10px", fontWeight: "bold", color: "#FFF", textAlign: "center", backgroundColor: "#262626", borderRadius: "4px", padding: "3px", paddingLeft: "8px", paddingRight: "8px" }}>
                          OBRIGATÓRIO
                        </Typography>
                      }
                      <div style={{ width: 10 }} />
                      <MoreVert style={{ marginLeft: validaIsObrigatorio() ? "" : "auto", color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditGrupoOpc(event)} />
                    </div>

                    <div style={{ padding: 16 }}>

                      {complementosArray && complementosArray.map((complemento, index) => (
                        (nomeGrupo === complemento.nomeGrupo && novoGrupo) || (grupoOriginal === complemento.nomeGrupo && !novoGrupo) &&
                        <>
                          <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                            <Typography style={{ width: "100%", lineHeight: "1.2" }}>
                              <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{complemento.nomeComplemento}</b><br />
                              {complemento.descricaoComplemento && (
                                <>
                                  <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: complemento.descricaoComplemento.replaceAll("\n", "<br/>") }}></b><br />
                                </>
                              )}
                              <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {complemento.precoComplemento}</b>
                            </Typography>
                            {!complemento.vIsOpcional && (
                              <>
                                <div style={{ position: "absolute", right: "90px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "80px", height: "40px", display: "flex", alignItems: "center" }}>
                                  <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", fontSize: "12px" }}>1</p>
                                  <RemoveOutlined style={{ position: "absolute", left: "8px", color: "#464545", fontSize: "14px" }} />
                                  <AddOutlined style={{ position: "absolute", right: "8px", color: "#464545", fontSize: "14px" }} />
                                </div>
                              </>
                            )}
                            {complemento.vIsOpcional && (
                              <Radio
                                checked
                              />
                            )}
                            <MoreVert style={{ color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditOpc(event, complemento)} />
                            <Menu id="opcoes2" anchorEl={anchorElEditOpc} open={Boolean(anchorElEditOpc && selectedComplemento === complemento)} onClose={handleCloseEditOpc}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              sx={{ marginTop: 1 }}
                            >

                              <List style={{ width: 200, fontSize: 16 }}>
                                <ListItem button onClick={() => handleOpt("duplicar", complemento)}>
                                  <ContentCopyOutlined style={{ marginRight: 14, color: "#464545" }} />
                                  <ListItemText primary="Duplicar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                </ListItem>
                                <ListItem button onClick={() => handleOpt("editar", complemento, index)}>
                                  <EditOutlined style={{ marginRight: 14, color: "#464545" }} />
                                  <ListItemText primary="Editar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                </ListItem>
                                <ListItem button onClick={() => handleOpt("excluir", complemento, index)}>
                                  <DeleteOutline style={{ marginRight: 14, color: "#464545" }} />
                                  <ListItemText primary="Excluir" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                </ListItem>
                              </List>
                            </Menu>
                          </Typography>
                          <div style={{ height: 16 }} />
                          <Divider />
                          <div style={{ height: 16 }} />
                        </>
                      ))}
                      {!isEditingComplemento && nomeComplemento ?
                        <>
                          <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center" }}>
                            <Typography style={{ width: "100%", lineHeight: "1.2" }}>
                              <b style={{ fontSize: "14px", color: "#1B1B1B", fontWeight: "bold" }}>{nomeComplemento}</b><br />
                              {descricaoComplemento && (
                                <>
                                  <b style={{ fontSize: "12px", color: "#999", fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: descricaoComplemento.replaceAll("\n", "<br/>") }}></b><br />
                                </>
                              )}
                              <b style={{ fontSize: "12px", color: "#999" }}>+ R$ {precoComplemento}</b>
                            </Typography>
                            {!validaIsOpcional() && (
                              <>
                                <div style={{ position: "absolute", right: "56px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "80px", height: "40px", display: "flex", alignItems: "center" }}>
                                  <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", fontSize: "12px" }}>1</p>
                                  <RemoveOutlined style={{ position: "absolute", left: "8px", color: "#464545", fontSize: "14px" }} />
                                  <AddOutlined style={{ position: "absolute", right: "8px", color: "#464545", fontSize: "14px" }} />
                                </div>
                              </>
                            )}
                            {validaIsOpcional() && (
                              <Radio
                                checked
                              />
                            )}
                          </Typography>
                        </>
                        :
                        <Typography style={{ color: "#DCDBDB", fontSize: 14 }}>
                          Sem complementos
                        </Typography>
                      }
                    </div>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </>
        }

        <Divider />
        <DialogActions>
          <Button variant="outlined" style={{ textTransform: "none" }} onClick={handleCloseModalComplemento}>Cancelar</Button>
          <Button variant="contained" style={{ textTransform: "none" }} onClick={handleAdicionarComplemento} >
            {!novoGrupo ? "Salvar" : "Criar grupo"}
          </Button>
        </DialogActions>

      </Dialog>
      <Dialog
        open={openModalProduto}
        onClose={handleCloseModalProduto}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : (importM ? (importando ? "" : 450) : 950) } }}
      >
        {importM ?
          <>
            <input
              type="file"
              ref={fileInputRef}
              accept=".csv"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {importando &&
              <>
                <div style={{ padding: 16 }}>
                  <Typography style={{ fontSize: 14, color: "#1B1B1B", textAlign: "center" }}>
                    <b>Importando...</b>
                    <div style={{ height: 20 }} />
                    <CircularProgress variant="determinate" value={uploadProgress} />
                    <br />
                    <label style={{ paddingLeft: 5 }}>{uploadProgress}%</label>
                  </Typography>

                </div>
              </>
            }
            {!importando &&
              <>
                <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
                  <UploadOutlined /> &nbsp;&nbsp;&nbsp;Importar planilha CSV
                  <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalProduto}>
                    <CloseOutlined />
                  </IconButton>
                </DialogTitle>
                <Divider />
                <div style={{ padding: 16 }}>
                  {fileImport.name ?
                    <div style={{ padding: 12, border: "solid 1px #464545", borderRadius: 8, display: "flex", alignItems: "center" }}>
                      <InsertDriveFileOutlined />
                      <div style={{ width: 8 }} />
                      <Typography style={{ fontSize: 14 }}>{fileImport.name}</Typography>
                      <div style={{ width: 8 }} />
                      <Typography style={{ fontSize: 12, color: "#999", marginLeft: "auto" }}>{fileImport.size}</Typography>
                      <div style={{ width: 8 }} />
                      <CheckCircleOutline style={{ color: "#33550F" }} />
                      <div style={{ width: 8 }} />
                      <Close onClick={handleRemoveCsv} style={{ cursor: "pointer" }} />
                    </div>
                    :
                    <div style={{ backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23DCDBDBFF' stroke-width='3' stroke-dasharray='8%2c12' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`, borderRadius: "8px", padding: 32, textAlign: "center" }} onClick={handleImportCsv}>
                      <Upload style={{ color: "#999999" }} />
                      <Typography style={{ fontSize: 12, color: "#999999" }}>
                        <b style={{ fontSize: 14 }}>Clique aqui fazer upload do arquivo</b><br />
                        Limite de 300MB
                      </Typography>
                    </div>
                  }
                </div>
                <Divider />
                <DialogActions>
                  <Button variant="outlined" style={{ textTransform: "none" }} onClick={handleCloseModalProduto}>Cancelar</Button>
                  <Button variant="contained" style={{ textTransform: "none" }} onClick={handleImportarCsv} >
                    Importar
                  </Button>
                </DialogActions>
              </>
            }
          </>
          :
          <>
            <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
              {itemSelected ? "Atualizar" : "Adicionar"}
              <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseModalProduto}>
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
            <Divider />
            <Tabs value={abaCardapio} onChange={handleChange} style={{ margin: 16, borderBottom: "solid 1px #e0e0e0" }} allowScrollButtonsMobile variant="fullWidth" scrollButtons="auto">
              <Tab label="Detalhes" {...a11yProps(0)} style={{ textTransform: "none" }} />
              <Tab label="Preço e Estoque" {...a11yProps(1)} style={{ textTransform: "none" }} />
              <Tab label="Adicionais" {...a11yProps(2)} style={{ textTransform: "none" }} />
              {/* <Tab label="Classificação" {...a11yProps(3)} style={{ textTransform: "none" }} />
            <Tab label="Disponibilidade" {...a11yProps(3)} style={{ textTransform: "none" }} /> */}
            </Tabs>
            {abaCardapio === 0 && // Aba detalhes
              <>
                <Grid container spacing={2} style={{ padding: 24 }}>
                  <Grid item xs={12} sm={12} md={6} style={{ borderRight: isMobile ? "" : "solid 1px #e0e0e0", paddingRight: isMobile ? 0 : 24, paddingTop: 0 }}>
                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Categoria <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        value={categoriaProduto}
                        onChange={handleCategoriaProduto}
                      >
                        {categorias && categorias.map((categoria) => (
                          <MenuItem value={categoria.categoria}>{categoria.categoria}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div style={{ height: 10 }} />
                    <Button startIcon={<Add />} onClick={handleAddCategoria}> Adicionar categoria</Button>

                    {addCategoria &&
                      <div style={{ padding: 16, background: "#F2F2F2", borderRadius: 8 }}>
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Adicionar categoria <b style={{ color: "#A51D1D" }}>*</b>
                        </Typography>
                        <div style={{ height: 4 }}></div>
                        <TextField
                          fullWidth
                          style={{ background: "#fff" }}
                          value={novaCategoria}
                          onChange={handleNovaCategoria}
                          variant="outlined"
                          size="large"
                          placeholder="Insira o nome da nova categoria"
                          type="text"
                        />
                        <Button onClick={handleCancelarNovaCategoria} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }}>Cancelar</Button>
                        <Button onClick={handleAddNovaCategoria} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }}>Salvar</Button>
                      </div>
                    }
                    <div style={{ height: 24 }} />
                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Nome do item <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={nomeProduto}
                      onChange={handleNomeProduto}
                      variant="outlined"
                      size="large"
                      placeholder="Insira o nome do item"
                      type="text"
                    />

                    <div style={{ height: 24 }} />
                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Descrição <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={descricaoProduto}
                      onChange={handleDescricaoProduto}
                      variant="outlined"
                      size="large"
                      placeholder="Insira a descrição"
                      type="text"
                    />
                    {isPizza &&
                      <>
                        <div style={{ height: 24 }} />
                        <FormControl>
                          <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                            Quantidade de Sabores <b style={{ color: "#A51D1D" }}>*</b>
                          </Typography>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={qtdSabores}
                            onChange={handleQtdSabores}
                          >
                            <FormControlLabel value="1" control={<Radio />} label="1" />
                            <FormControlLabel value="2" control={<Radio />} label="2" />
                            <FormControlLabel value="3" control={<Radio />} label="3" />
                            <FormControlLabel value="4" control={<Radio />} label="4" />
                          </RadioGroup>
                        </FormControl>
                      </>
                    }
                    <div style={{ height: 16 }} />
                    <Divider />
                    <div style={{ height: 16 }} />

                    <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                      <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600" }}>
                        <b>Este item é unitário?<br /><b style={{ fontSize: 14, fontWeight: "400" }}>Marque essa opção se o item é por pessoa ou unitário</b></b>
                        <FormControlLabel style={{ marginLeft: "auto" }}
                          control={<MaterialUISwitch sx={{ m: 1 }} checked={habilitaUnitario} onChange={handleHabilitaUnitario} />}
                        />
                      </Typography>
                    </div>

                    {/* <div style={{ height: 24 }} />
                <Divider />
                <div style={{ height: 16 }} />

                <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                  Código PDV
                </Typography>
                <div style={{ height: 4 }}></div>
                <TextField
                  fullWidth
                  // value={mesa}
                  // onChange={handleMesa}
                  variant="outlined"
                  size="large"
                  placeholder="Digite o numero da mesa"
                  type="text"
                /> */}

                  </Grid>
                  <Grid item xs={12} sm={12} md={6} style={{ paddingTop: isMobile ? 16 : 0 }}>
                    <div style={{ border: "solid 1px #e0e0e0", padding: 24, borderRadius: 8 }}>
                      <Typography style={{ textAlign: "left", fontWeight: "600", fontSize: 16 }}>
                        Imagens do item
                      </Typography>
                      <Typography style={{ textAlign: "left", fontWeight: "400", fontSize: 14 }}>
                        Aparece na listagem do cardápio e nos detalhes do prato.
                      </Typography>

                      <div style={{ height: 16 }} />

                      {isMobile ?
                        <Grid container spacing={2} style={{ padding: 24 }}>
                          {[...Array(5)].map((_, index) => (
                            <div key={index} style={{ margin: 4, position: 'relative' }}>
                              <input
                                type="file"
                                accept="image/*"
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  opacity: 0,
                                  cursor: 'pointer'
                                }}
                                ref={fileInputRefProdutos}
                                onChange={(e) => handleImageChange(e, index)}
                              />
                              {imagensProduto[index] ? (
                                <div style={{ position: 'relative', width: 60, height: 60 }}>
                                  <img
                                    src={imagensProduto[index]}
                                    alt={`Upload ${index}`}
                                    style={{ width: '100%', height: '100%', borderRadius: 7.5 }}
                                  />
                                  <button
                                    style={{
                                      position: 'absolute',
                                      width: 22,
                                      height: 22,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      top: 5,
                                      right: 5,
                                      background: 'rgba(0, 0, 0, 0.7)',
                                      border: 'none',
                                      borderRadius: '50%',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    <CloseOutlined style={{ fontSize: 16, color: "#FFF" }} />
                                  </button>
                                </div>
                              ) : (
                                <Tooltip title={`Clique para inserir uma foto nesse campo`}>
                                  <svg width="60" height="60" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="73" height="73" rx="7.5" stroke="#999999" stroke-dasharray="8 8" />
                                    <g clipPath="url(#clip0_3979_40426)">
                                      <g clipPath="url(#clip1_3979_40426)">
                                        <path d="M43 45H29V31H38V29H29C27.9 29 27 29.9 27 31V45C27 46.1 27.9 47 29 47H43C44.1 47 45 46.1 45 45V36H43V45ZM35.21 41.83L33.25 39.47L30.5 43H41.5L37.96 38.29L35.21 41.83ZM45 29V26H43V29H40C40.01 29.01 40 31 40 31H43V33.99C43.01 34 45 33.99 45 33.99V31H48V29H45Z" fill="#999999" />
                                      </g>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_3979_40426">
                                        <rect x="17" y="17" width="40" height="40" rx="8" fill="white" />
                                      </clipPath>
                                      <clipPath id="clip1_3979_40426">
                                        <rect width="24" height="24" fill="white" transform="translate(25 25)" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Tooltip>
                              )}
                            </div>
                          ))}
                        </Grid>
                        :
                        <div style={{ display: 'flex' }}>
                          {[...Array(5)].map((_, index) => (
                            <div key={index} style={{ margin: 4, position: 'relative' }}>
                              <input
                                type="file"
                                accept="image/*"
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  opacity: 0,
                                  cursor: 'pointer'
                                }}
                                ref={fileInputRefProdutos}
                                onChange={(e) => handleImageChange(e, index)}
                              />
                              {imagensProduto[index] ? (
                                <div style={{ position: 'relative', width: 74, height: 74 }}>
                                  <img
                                    src={imagensProduto[index]}
                                    alt={`Upload ${index}`}
                                    style={{ width: '100%', height: '100%', borderRadius: 7.5 }}
                                  />
                                  <button
                                    style={{
                                      position: 'absolute',
                                      width: 22,
                                      height: 22,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      top: 5,
                                      right: 5,
                                      background: 'rgba(0, 0, 0, 0.7)',
                                      border: 'none',
                                      borderRadius: '50%',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    <CloseOutlined style={{ fontSize: 16, color: "#FFF" }} />
                                  </button>
                                </div>
                              ) : (
                                <Tooltip title={`Clique para inserir uma foto nesse campo`}>
                                  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="73" height="73" rx="7.5" stroke="#999999" stroke-dasharray="8 8" />
                                    <g clipPath="url(#clip0_3979_40426)">
                                      <g clipPath="url(#clip1_3979_40426)">
                                        <path d="M43 45H29V31H38V29H29C27.9 29 27 29.9 27 31V45C27 46.1 27.9 47 29 47H43C44.1 47 45 46.1 45 45V36H43V45ZM35.21 41.83L33.25 39.47L30.5 43H41.5L37.96 38.29L35.21 41.83ZM45 29V26H43V29H40C40.01 29.01 40 31 40 31H43V33.99C43.01 34 45 33.99 45 33.99V31H48V29H45Z" fill="#999999" />
                                      </g>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_3979_40426">
                                        <rect x="17" y="17" width="40" height="40" rx="8" fill="white" />
                                      </clipPath>
                                      <clipPath id="clip1_3979_40426">
                                        <rect width="24" height="24" fill="white" transform="translate(25 25)" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Tooltip>
                              )}
                            </div>
                          ))}
                        </div>
                      }


                      <div style={{ height: 16 }} />

                      <Typography style={{ textAlign: "left", fontWeight: "400", fontSize: 14 }}>
                        Apenas imagens JPG e PNG até 50MB. <br />
                        Resolução miníma: 300x275
                      </Typography>

                      {/* <div style={{ height: 16 }} />

                      <Button fullWidth size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} onClick={handleCarregarFoto} startIcon={<UploadOutlined />}>
                        Carregar foto
                      </Button> */}

                    </div>


                  </Grid>
                </Grid>
              </>
            }

            {abaCardapio === 1 && // Aba preço e estoque
              <>
                <Grid container spacing={2} style={{ padding: 24 }}>
                  <Grid item xs={12} sm={12} md={12} style={{ paddingRight: isMobile ? 0 : 24, paddingTop: 0 }}>
                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Preço <b style={{ color: "#A51D1D" }}>*</b>
                    </Typography>
                    <div style={{ height: 4 }}></div>
                    <TextField
                      fullWidth
                      value={precoProduto}
                      onChange={handlePreco}
                      variant="outlined"
                      size="large"
                      placeholder="Insira o preço do item"
                      type="text"
                    />
                    <div style={{ height: 14 }} />
                    {isPizza &&
                      <>
                        <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                          Preço da broto
                        </Typography>
                        <div style={{ height: 4 }}></div>
                        <TextField
                          fullWidth
                          value={valorBroto}
                          onChange={handleValorBroto}
                          variant="outlined"
                          size="large"
                          placeholder="Insira o preço da broto"
                          type="text"
                        />
                        <div style={{ height: 14 }} />
                      </>
                    }
                    <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                      <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600" }}>
                        <b>Habilitar resgate por pontos?<br /><b style={{ fontSize: 14, fontWeight: "400" }}>O item pode ser resgatado pela quantidade de pontos definida abaixo</b></b>
                        <FormControlLabel style={{ marginLeft: "auto" }}
                          control={<MaterialUISwitch sx={{ m: 1 }} checked={habilitaResgate} onChange={handleHabilitaResgate} />}
                        />
                      </Typography>
                      <div style={{ height: 10 }} />
                      <Divider />
                      <div style={{ height: 10 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Valor do resgate
                      </Typography>
                      <div style={{ height: 4 }}></div>
                      <TextField
                        fullWidth
                        value={valorPontos}
                        onChange={handleValorPontos}
                        style={{ background: "#fff", pointerEvents: !habilitaResgate ? 'none' : 'auto', opacity: !habilitaResgate ? 0.5 : 1 }}
                        variant="outlined"
                        size="large"
                        placeholder="Insira o desconto do item"
                        type="text"
                      />
                    </div>
                    <div style={{ height: 24 }} />
                    <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                      <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600" }}>
                        <b>Aplicar desconto?<br /><b style={{ fontSize: 14, fontWeight: "400" }}>Aplique o preço com desconto definindo o valor abaixo</b></b>
                        <FormControlLabel style={{ marginLeft: "auto" }}
                          control={<MaterialUISwitch sx={{ m: 1 }} checked={aplicarDesconto} onChange={handleAplicarDesconto} />}
                        />
                      </Typography>
                      <div style={{ height: 10 }} />
                      <Divider />
                      <div style={{ height: 10 }} />
                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Valor do desconto
                      </Typography>
                      <div style={{ height: 4 }}></div>
                      <TextField
                        fullWidth
                        value={descontoProduto}
                        onChange={handleDescontoProduto}
                        style={{ background: "#fff", pointerEvents: !aplicarDesconto ? 'none' : 'auto', opacity: !aplicarDesconto ? 0.5 : 1 }}
                        variant="outlined"
                        size="large"
                        placeholder="Insira o desconto do item"
                        type="text"
                      />
                    </div>
                    <div style={{ height: 24 }} />
                    <div style={{ background: "#F2F2F2", padding: 16, borderRadius: 8 }}>
                      <Typography style={{ display: "flex", fontSize: 16, fontWeight: "600", alignItems: "center" }}>
                        <b>O item possui estoque?</b>
                        <FormControlLabel style={{ marginLeft: "auto" }}
                          control={<MaterialUISwitch sx={{ m: 1 }} checked={possuiEstoque} onChange={handlePossuiEstoque} />}
                        />
                      </Typography>
                      <div style={{ height: 10 }} />
                      <Divider />
                      <div style={{ height: 10 }} />
                      <Typography style={{ display: "flex", textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        <b>Quantidade disponível em estoque<br /><b style={{ fontSize: 14, fontWeight: "400" }}>O item desativa automaticamente quando não estiver estoque.</b></b>
                        <div style={{ position: "relative", marginLeft: "auto", right: "0px", pointerEvents: !possuiEstoque ? 'none' : 'auto', opacity: !possuiEstoque ? 0.5 : 1, backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", maxWidth: 131, minWidth: 131, height: "40px", display: "flex", alignItems: "center" }}>
                          <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{estoqueProduto ? estoqueProduto : 0}</p>
                          <RemoveOutlined onClick={() => handleEstoque("rm")} style={{ position: "absolute", left: "16px", color: "#464545", cursor: "pointer" }} />
                          <AddOutlined onClick={() => handleEstoque("add")} style={{ position: "absolute", right: "16px", color: "#464545", cursor: "pointer" }} />
                        </div>
                      </Typography>

                    </div>
                  </Grid>
                </Grid>
              </>
            }

            {abaCardapio === 2 && // Aba complementos
              <>
                <Grid container spacing={2} style={{ padding: 24 }}>
                  <Grid item xs={12} sm={12} md={12} style={{ paddingRight: isMobile ? 0 : 24, paddingTop: 0 }}>
                    <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                      Grupo de complementos
                    </Typography>
                    <div style={{ height: 16 }} />
                    <Button variant="outlined" style={{ textTransform: "none" }} fullWidth={isMobile} startIcon={<Add />} onClick={handleCriarGrupoComplementos}>Criar grupo</Button>
                    {/* <Button variant="outlined" style={{ textTransform: "none", marginLeft: 16, marginTop: isMobile ? 16 : 0 }} fullWidth={isMobile} startIcon={<ContentCopyOutlined />} onClick={handleCloseModalProduto}>Copiar de outro item</Button> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ paddingRight: isMobile ? 0 : 24, paddingTop: 20 }}>
                    <div style={{ border: "solid 1px #dfdfdf", padding: "16px 16px 0px 16px", borderRadius: 8 }}>
                      {complementosArray && complementosArray.length > 0 ?
                        <>{gerarComplementos(complementosArray)}</>
                        :
                        <>
                          <div style={{ textAlign: "center", fontSize: 14, fontWeight: 400, color: "#999999", padding: 16 }}>
                            <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                            <div style={{ height: 10 }} />
                            Você ainda não cadastrou nenhum complemento. <br />
                            Que tal adicionar o primeiro agora?
                            <div style={{ height: 20 }} />
                          </div>
                        </>
                      }

                    </div>
                  </Grid>

                </Grid>
              </>
            }
            <Divider />
            <DialogActions>
              <Button variant="outlined" style={{ textTransform: "none" }} onClick={handleCloseModalProduto}>Cancelar</Button>
              <Button variant="contained" style={{ textTransform: "none" }} onClick={handleAdicionarProduto} >
                {itemSelected ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </>
        }
      </Dialog>
      {
        !items &&
        <>
        </>
      }
      {
        (items && items.length === 0) || !items ?
          <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
            <Grid container spacing={2} style={{ padding: 16 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Cardápio</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} style={{ height: "calc(100vh - 135px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ textAlign: "center", fontSize: 22, fontWeight: 400, color: "#999999" }}>
                      <img src="https://dedicado.mely.online/imagens/no-order.png" style={{ width: 155 }} />

                      <div style={{ height: "10px" }}></div>

                      Você ainda não cadastrou nenhum item.<br />
                      Que tal adicionar o primeiro agora?
                      <div style={{ height: 20 }} />
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button variant="contained" color="secondary" style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleOpenModalImport}>Importar</Button>
                        <div style={{ width: 20 }} />
                        <Button variant="contained" style={{ fontWeight: "bold", textTransform: "none" }} onClick={handleOpenModalProduto}>Adicionar</Button>
                      </div>
                      <div style={{ height: "20px" }}></div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </div>
          :
          <>
            <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
              <Grid container spacing={2} style={{ padding: 16 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Cardápio</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <div style={{ height: isMobile ? 4 : 40 }} />
                              <Typography style={{ fontWeight: "bold", color: "#1B1B1B", fontSize: 14 }}>Categorias</Typography>


                              <List>
                                {items &&
                                  <>
                                    {items.map((produto) => (
                                      !produto.tipo && produto.categoria !== "" && (
                                        <>
                                          <ListItem disablePadding style={{ backgroundColor: "" }}>
                                            <ListItemButton onClick={handleExpanded(produto.categoriaOriginal)}>
                                              <ListItemText primary={<Typography style={{ display: "flex" }}>{produto.categoriaOriginal}<KeyboardArrowRight style={{ marginLeft: "auto" }} /></Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                                            </ListItemButton>
                                          </ListItem>
                                          <Divider />
                                        </>
                                      )
                                    ))}
                                  </>
                                }
                              </List>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: isMobile ? 0 : 16, paddingTop: isMobile ? 16 : 0 }}>

                          <div style={{ height: isMobile ? 0 : 40 }} />
                          {!itensSelected &&
                            <>
                              <Button variant="contained" size="large" fullWidth={isMobile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5 }} onClick={handleOpenModalProduto}>
                                Adicionar
                              </Button>
                              <Button variant="outlined" size="large" fullWidth={isMobile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5 }} onClick={handleOpenModalImport}>
                                Importar
                              </Button>
                            </>
                          }
                          {itensSelected &&
                            <>
                              <Button variant="outlined" size="large" fullWidth={isMobile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5 }} endIcon={<KeyboardArrowDown />} onClick={handleOpenStatus}>
                                Status
                              </Button>
                              <Menu id="status" anchorEl={anchorEl} open={openStatus} onClose={handleCloseStatus}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                                sx={{ marginTop: 1 }}
                              >

                                <List style={{ width: 315, fontSize: 16 }}>
                                  <ListItem button onClick={() => handleUpdateAllStatus("ativar")}>
                                    <ToggleOnOutlined style={{ marginRight: 14, color: "#464545" }} />
                                    <ListItemText primary="Ativar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                  </ListItem>
                                  <ListItem button onClick={() => handleUpdateAllStatus("desativar")}>
                                    <ToggleOffOutlined style={{ marginRight: 14, color: "#464545" }} />
                                    <ListItemText primary="Desativar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                  </ListItem>
                                </List>
                              </Menu>
                              <Button variant="outlined" size="large" fullWidth={isMobile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5 }} onClick={handleDeletarSelecionados}>
                                Deletar
                              </Button>
                            </>
                          }


                          <div style={{ height: 20 }} />

                          {items &&
                            <>
                              {items.map((produto) => (
                                !produto.tipo && produto.categoria !== "" && (
                                  <>
                                    <Accordion expanded={expandedArr[produto.categoriaOriginal] || false} onChange={handleExpanded(produto.categoriaOriginal)} style={{ border: "solid 1px #dfdfdf" }}>
                                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>{produto.categoriaOriginal}</Typography>
                                        <Typography style={{ fontSize: 14, color: "#999999", marginLeft: "auto", paddingTop: 2, marginRight: 4 }}>{totalItens(produto.categoriaOriginal)}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {items.map((itens, count) => (
                                          itens.categoriaOriginal === produto.categoriaOriginal && !itens.tipo && (
                                            <>
                                              {itens.categoria === "" &&
                                                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                              }

                                              <ListItem disablePadding style={{ backgroundColor: "" }}>
                                                <ListItemButton onClick={() => handleItem(itens)}>
                                                  <FormGroup style={{ marginRight: "auto", marginLeft: 16 }}>
                                                    <FormControlLabel control={<Checkbox checked={itensSelected && itensSelected.includes(itens.idprodutos)} onChange={() => handleItemSelect(itens)} />} onClick={(event) => event.stopPropagation()} />
                                                  </FormGroup>
                                                  <CardMedia component="img" image={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${itens.idprodutos}_0.png?${new Date().getTime()}`} style={{ width: 56, height: 56, backgroundColor: "#222" }} onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                                                  }}
                                                  />
                                                  <div style={{ width: isMobile ? 4 : 18 }} />
                                                  <ListItemText primary={<Typography style={{ display: "flex", alignItems: "center" }}><label style={{ color: "#999", fontSize: 16, cursor: "pointer" }}><b style={{ fontSize: 12, color: itens.status === "I" ? "#F94A3D" : "#464545" }}>{itens.status === "I" ? "Desativado" : "Ativado"}</b> {itens.destaque === "S" ? <Star style={{ fontSize: 20, marginBottom: -4, color: "gold" }} /> : ""}<br />{itens.nome}<br /><b style={{ fontSize: 14, fontWeight: "normal" }}>{itens.descricao}</b></label><br /><label style={{ marginLeft: "auto", display: "flex", alignItems: "center", color: `rgba(${colorPrimary})`, cursor: "pointer" }}> R$ {itens.valor}<div style={{ width: isMobile ? 4 : 18 }} /><MoreVert style={{ color: "#1b1b1b", cursor: "pointer" }} onClick={(event) => handleOpenEditItem(event, itens)} /></label></Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                                                  <Menu id="item" anchorEl={anchorElEditItem} open={Boolean(anchorElEditItem && selectedComplemento === itens)} onClose={handleCloseEditItem}
                                                    MenuListProps={{
                                                      'aria-labelledby': 'basic-button',
                                                    }}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'center',
                                                    }}
                                                    sx={{ marginTop: 1 }}
                                                  >

                                                    <List style={{ width: 200, fontSize: 16 }}>
                                                      <ListItem button onClick={(event) => handleOptItem(event, "status", itens)}>
                                                        {itens.status === "I" ?
                                                          <ToggleOnOutlined style={{ marginRight: 14, color: "#464545" }} />
                                                          :
                                                          <ToggleOffOutlined style={{ marginRight: 14, color: "#464545" }} />
                                                        }
                                                        <ListItemText primary={itens.status === "I" ? "Ativar" : "Desativar"} primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                                      </ListItem>
                                                      <ListItem button onClick={(event) => handleOptItem(event, "excluir", itens)}>
                                                        <DeleteOutline style={{ marginRight: 14, color: "#464545" }} />
                                                        <ListItemText primary="Excluir" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                                      </ListItem>
                                                      <ListItem button onClick={(event) => handleOptItem(event, "destaque", itens)}>
                                                        {itens.destaque === "S" ? <Star style={{ marginRight: 14, color: "#464545" }} /> : <StarBorder style={{ marginRight: 14, color: "#464545" }} />}
                                                        <ListItemText primary={"Destaque"} primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                                      </ListItem>
                                                    </List>
                                                  </Menu>
                                                </ListItemButton>
                                              </ListItem>

                                            </>
                                          )

                                        ))}
                                      </AccordionDetails>
                                    </Accordion>
                                    <div style={{ height: 16 }} />

                                  </>
                                )
                              ))}
                            </>
                          }
                        </Grid>


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </div>
          </>
      }

    </ThemeProvider >
  );
}

export default Cardapio;