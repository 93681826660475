import { useState, useEffect, useRef } from 'react';
import { ThemeProvider, styled } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, createNewTheme } from '../../functions/utils';
import { Button, Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert } from '@mui/material';
import { AddOutlined, CloseOutlined, DragIndicator, KeyboardArrowDownOutlined, KeyboardArrowRight, PhoneOutlined, RemoveOutlined, SettingsOutlined, WhatsApp } from '@mui/icons-material';
import Cookies from 'js-cookie';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, MaterialUISwitch } from '../../components/utils';

function Inventario({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tipoSuporte, setTipoSuporte] = useState(0);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagen] = useState("");
  const [estoqueMinimoAtivo, setEstoqueMinimoAtivo] = useState(false);
  const [estoqueMinimo, setEstoqueMinimo] = useState(0);
  const [items, setItems] = useState(null);
  const [itemSelected, setItemSelected] = useState([]);
  const [itemsOrigim, setItemsOrigim] = useState([]);
  const [expandedArr, setExpandedArr] = useState([]);
  const [openConfiguracoes, setOpenConfiguracoes] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const [estoque, setEstoque] = useState(0);
  const [isEstoqueMinimo, setIsEstoqueMinimo] = useState(false);

  const handleEditClick = (item) => {
    setIsEditing(item);
    setEstoque(item.estoque)
  };

  const handleBlur = (item) => {
    setIsEditing(false);
    if (estoque && parseInt(estoque) > -1) {
      const urlBase = Cookies.get('UBs');
      const post_url = `${urlBase}/editar-estoque.php`;
      const json = JSON.stringify({
        idprodutos: item.idprodutos,
        estoque: parseInt(estoque),
      });

      axios.post(post_url, json)
        .then(resp => {
          const response = resp.data
          if (response) {
            if (response.resposta === "sucesso") {
              const urlBase = Cookies.get('UBs');
              getProdutos(urlBase)
              return
            }
          }
          update("", `${Date.now()}___error___Não foi possivel atualizar seu estoque. Por favor verifique sua conexão e tente novamente.`)
        })
        .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
    }
  };

  const handleChange = (e) => {
    setEstoque(e.target.value);
  };

  const handleConfiguracoes = () => {
    setOpenConfiguracoes(true);
  };

  const handleCloseConfiguracoes = () => {
    setOpenConfiguracoes(false);
  };

  const handleSalvarConfiguracoes = () => {
    Cookies.set('eMA', estoqueMinimoAtivo, { expires: 7 });
    if (estoqueMinimoAtivo) {
      setTimeout(() => {
        update("", `${Date.now()}___estoque___${estoqueMinimoAtivo}`)
      }, 1000);
    }
    Cookies.set('eMAv', estoqueMinimo, { expires: 7 });
    update("", `${Date.now()}___success___Suas configurações foram atualizadas com sucesso.`)
    setOpenConfiguracoes(false)
  };

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpandedArr((prevExpandedArr) => ({
      ...prevExpandedArr,
      [panel]: newExpanded !== undefined ? newExpanded : true,
    }));
    console.log(newExpanded)
  };

  const handleSuporteChange = (event, newValue) => {
    setTipoSuporte(newValue);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleMensagem = (event) => {
    setMensagen(event.target.value);
  };

  useEffect(() => {
    Cookies.get('eMA') ? setEstoqueMinimoAtivo(Cookies.get('eMA') === "true") : console.log("-")
    Cookies.get('eMAv') ? setEstoqueMinimo(Cookies.get('eMAv')) : console.log("-")
    const email = Cookies.get('emEst');
    setEmail(email)
    const urlBase = Cookies.get('UBs');
    getProdutos(urlBase)
  }, []);

  const getProdutos = (url) => {
    axios.post(`${url}/produtos-inventario.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let responseOrigin = response.data
        response = response.data
        if (response) {

          // ajusta as categorias
          let ultimaCategoria = "", ultimaSubcategoria = "", ultimoProduto = "", arrExpanded = []
          setIsEstoqueMinimo(false)
          for (const produto of response) {
            if (Cookies.get('eMA') === "true") {
              const estoqueMinimo = parseInt(Cookies.get('eMAv'))
              if (parseInt(produto.estoque) <= estoqueMinimo) {
                setIsEstoqueMinimo(true)
              }
            }
            const partes = produto.categoria.split(' - ');
            const categoriaNumero = partes[0];
            const categoriaOriginal = partes[1];
            const categoriaProduto = partes.slice(2).join(' - ');
            produto.categoriaNumero = categoriaNumero
            produto.categoriaOriginal = categoriaOriginal
            produto.categoriaProduto = categoriaProduto
            produto.subcategoriaOriginal = produto.subcategoria
            arrExpanded[categoriaOriginal] = false
            if (ultimaCategoria == "" || ultimaCategoria != produto.categoria) {
              if (!produto.tipo) {
                if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                  ultimaCategoria = produto.categoria
                  ultimaSubcategoria = produto.subcategoria
                  ultimoProduto = categoriaProduto
                  produto.isFirst = "true"
                  continue
                } else {
                  if (produto.subcategoria == "") {
                    ultimaSubcategoria = produto.subcategoria
                  }
                  ultimaCategoria = produto.categoria
                  ultimoProduto = categoriaProduto
                  produto.isFirst = "true"
                  continue
                }
              } else {
                if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                  ultimaCategoria = produto.categoria
                  ultimaSubcategoria = produto.subcategoria
                  ultimoProduto = categoriaProduto
                  produto.isFirst = "true"
                  continue
                }
              }
            } else {
              if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                ultimaCategoria = produto.categoria
                ultimaSubcategoria = produto.subcategoria
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              }
            }

            if (produto.tipo) {
              if (categoriaProduto !== ultimoProduto) {
                produto.isFirst = "true"
              }
            }
            ultimoProduto = categoriaProduto

            produto.categoria = ""
            produto.subcategoria = ""
          }
          // setExpandedArr(arrExpanded)
          // handleCloseLoading();
          setItems(response);
          setItemsOrigim(response);
        }
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  }

  const totalItens = (it) => {
    const arrCount = itemsOrigim.filter(item => item.categoriaOriginal === it)
    let arrItensEstoque = []
    if (Cookies.get('eMA') === "true") {
      const estoqueMinimo = parseInt(Cookies.get('eMAv'))
      arrItensEstoque = itemsOrigim.filter(item => item.categoriaOriginal === it && parseInt(item.estoque) <= parseInt(estoqueMinimo))
    }
    return <Typography style={{ display: "flex", alignItems: "center", fontSize: 14 }}>{arrItensEstoque.length > 0 ? <><div style={{ borderRadius: "50%", background: "tomato", height: 14, width: 14, marginTop: -2 }} />&nbsp;&nbsp;&nbsp;</> : <></>}
      {arrCount.length > 1 ? <>{arrCount.length} itens</> : <>{arrCount.length} item</>}
    </Typography>
  }

  const handleHabilitaItem = (item) => {
    const urlBase = Cookies.get('UBs');
    const post_url = `${urlBase}/editar-estoque.php`;
    const json = JSON.stringify({
      idprodutos: item.idprodutos,
      estoque: item.estoque ? "null" : 0,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso") {
            const urlBase = Cookies.get('UBs');
            getProdutos(urlBase)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel atualizar seu estoque. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleEstoqueMinimoAtivo = () => {
    setEstoqueMinimoAtivo(!estoqueMinimoAtivo)
  }

  const handleUpdateEstoqueMinimo = (tipo) => {
    let estoque = estoqueMinimo ? parseInt(estoqueMinimo) : 0
    tipo === "add" ? estoque += 1 : estoque -= 1
    estoque < 1 ? estoque = 1 : estoque = estoque
    setEstoqueMinimo(estoque)
  }

  const handleUpdateEstoque = (item, tipo) => {
    let estoque = item.estoque ? parseInt(item.estoque) : 0
    tipo === "add" ? estoque -= 1 : estoque += 1
    if (estoque < 0) {
      return
    }
    const urlBase = Cookies.get('UBs');
    const post_url = `${urlBase}/editar-estoque.php`;
    const json = JSON.stringify({
      idprodutos: item.idprodutos,
      estoque: estoque,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso") {
            const urlBase = Cookies.get('UBs');
            getProdutos(urlBase)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel atualizar seu estoque. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  const handleUpdateAll = (tipo) => {

    const urlBase = Cookies.get('UBs');
    const post_url = `${urlBase}/editar-todos-estoque.php`;
    const json = JSON.stringify({
      estoque: tipo,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "sucesso") {
            const urlBase = Cookies.get('UBs');
            getProdutos(urlBase)
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel atualizar seu estoque. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));
  };

  useEffect(() => {
    if (isEditing !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const getValorEstoque = (item) => {
    return <>
      {isEditing === item ? (
        <input
          type="text"
          value={estoque}
          ref={inputRef}
          onBlur={() => handleBlur(item)}
          onChange={handleChange}
          style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700", width: "100%", outline: "none", border: "none", fontSize: 16 }}
        />
      ) : (
        <p onClick={() => handleEditClick(item)} style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>
          {item.estoque ? item.estoque : 0}
        </p>
      )}
    </>
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        {isEstoqueMinimo &&
          <>
            <Alert
              variant="outlined"
              severity="warning"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                color: "#464545", fontSize: 14,
                border: "none",
                background: "#fffae6"
              }}
            >
              <div>
                <b style={{ fontSize: 16 }}>Itens acabando no estoque</b><br />
                Verifique seus itens no estoque pois eles estão acabando
              </div>
            </Alert>

            <div style={{ height: "22px" }} />
          </>
        }

        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Estoque</Typography>
              </Grid>
              <Dialog
                open={openConfiguracoes}
                onClose={handleCloseConfiguracoes}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ '& .MuiPaper-root': { minWidth: isMobile ? "" : 470 } }}

              >
                <DialogTitle id="scroll-dialog-title" style={{ display: "flex", alignItems: "center", fontSize: 18, fontWeight: '600' }}>
                  <SettingsOutlined /> &nbsp;&nbsp;Configurações
                  <IconButton color="inherit" style={{ marginLeft: "auto" }} onClick={handleCloseConfiguracoes}>
                    <CloseOutlined />
                  </IconButton>
                </DialogTitle>
                <Divider />
                <div style={{ padding: 16, paddingRight: 0 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ fontSize: 14, color: "#1b1b1b", fontWeight: "400" }}>
                      <b style={{ fontSize: 18 }}>Estoque minimo</b><br />
                      Ative para ser notificado ao atingir o estoque minimo
                    </Typography>
                    <FormControlLabel style={{ marginLeft: "auto" }}
                      control={<MaterialUISwitch sx={{ m: 1 }} checked={estoqueMinimoAtivo} onChange={() => handleEstoqueMinimoAtivo()} />}
                    />
                  </div>
                  <div style={{ height: 10 }} />
                  <div style={{ paddingRight: 16 }}>
                    <Divider />
                  </div>
                  <div style={{ height: 10 }} />

                  <Grid item xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography style={{ fontSize: 14, color: "#1b1b1b", fontWeight: "400", paddingRight: 10 }}>
                        <b style={{ fontSize: 18 }}>Alerta de estoque mínimo</b><br />
                        Ao atingir a quantidade minima, envia um alerta. <br />Desative para não alertar.
                      </Typography>
                    </div>
                    <div style={{ width: 20 }} />
                    <div style={{ position: "relative", marginLeft: "auto", right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", maxWidth: 131, minWidth: 131, height: "40px", display: "flex", alignItems: "center", pointerEvents: 0 ? 'none' : 'auto', opacity: 0 ? 0.5 : 1 }}>
                      <p style={{ position: "absolute", right: "0", left: "0", textAlign: "center", fontFamily: "roboto", fontWeight: "700" }}>{estoqueMinimo}</p>
                      <RemoveOutlined style={{ cursor: "pointer", position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleUpdateEstoqueMinimo("rm")} />
                      <AddOutlined style={{ cursor: "pointer", position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleUpdateEstoqueMinimo("add")} />
                    </div>

                  </Grid>

                </div>

                <Divider />
                <DialogActions>
                  <Button variant="outlined" style={{ textTransform: "none" }} onClick={handleCloseConfiguracoes}>Cancelar</Button>
                  <Button variant="contained" style={{ textTransform: "none" }} onClick={handleSalvarConfiguracoes} >
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ height: isMobile ? 4 : 40 }} />
                        <Typography style={{ fontWeight: "bold", color: "#1B1B1B", fontSize: 14 }}>Categorias</Typography>


                        <List>
                          {items &&
                            <>
                              {items.map((produto) => (
                                !produto.tipo && produto.categoria !== "" && (
                                  <>
                                    <ListItem disablePadding style={{ backgroundColor: "" }}>
                                      <ListItemButton onClick={handleExpanded(produto.categoriaOriginal)}>
                                        <ListItemText primary={<Typography style={{ display: "flex" }}>{produto.categoriaOriginal}<KeyboardArrowRight style={{ marginLeft: "auto" }} /></Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                                      </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                  </>
                                )
                              ))}
                            </>
                          }
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: isMobile ? 0 : 16, paddingTop: isMobile ? 16 : 0 }}>

                    <div style={{ height: isMobile ? 0 : 40 }} />

                    <Button onClick={() => handleUpdateAll(0)} variant="outlined" size="large" fullWidth={isMobile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5 }}>
                      Ativar todos
                    </Button>
                    <Button onClick={() => handleUpdateAll("null")} variant="outlined" size="large" fullWidth={isMobile} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5 }}>
                      Desativar todos
                    </Button>

                    <Button variant="outlined" size="large" fullWidth={isMobile} startIcon={<SettingsOutlined />} style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: 5, marginRight: 5, float: isMobile ? "" : "right" }} onClick={handleConfiguracoes}>
                      Configurações
                    </Button>

                    <div style={{ height: 20 }} />

                    {items &&
                      <>
                        {items.map((produto) => (
                          !produto.tipo && produto.categoria !== "" && (
                            <>
                              <Accordion expanded={expandedArr[produto.categoriaOriginal] || false} onChange={handleExpanded(produto.categoriaOriginal)} style={{ border: "solid 1px #dfdfdf" }}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                  <Typography>{produto.categoriaOriginal}</Typography>
                                  <Typography style={{ fontSize: 14, color: "#999999", marginLeft: "auto", paddingTop: 2, marginRight: 4 }}>{totalItens(produto.categoriaOriginal)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {items.map((itens, count) => (
                                    itens.categoriaOriginal === produto.categoriaOriginal && (
                                      <>
                                        {itens.categoria === "" &&
                                          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                        }
                                        <Grid container spacing={0}>
                                          <Grid item xs={12} sm={12} md={10} style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ position: "relative", marginLeft: 10, right: "16px", backgroundColor: "white", padding: "5px", border: "solid #DCDBDB 1px", borderRadius: "8px", width: "131px", maxWidth: 131, minWidth: 131, height: "40px", display: "flex", alignItems: "center", pointerEvents: !itens.estoque ? 'none' : 'auto', opacity: !itens.estoque ? 0.5 : 1 }}>
                                              {getValorEstoque(itens)}
                                              <RemoveOutlined style={{ cursor: "pointer", position: "absolute", left: "16px", color: "#464545" }} onClick={() => handleUpdateEstoque(itens, "add")} />
                                              <AddOutlined style={{ cursor: "pointer", position: "absolute", right: "16px", color: "#464545" }} onClick={() => handleUpdateEstoque(itens, "rm")} />
                                            </div>
                                            <div>

                                              <Typography style={{ color: "#1B1B1B", fontSize: 16, fontWeight: 400, width: "100%", paddingRight: 10 }}>
                                                {itens.nome}<br />
                                                <b style={{ color: "#999999", fontSize: 14, fontWeight: 400 }}>{itens.descricao}</b>
                                              </Typography>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={2} style={{ display: "flex", alignItems: "center" }}>
                                            <Typography style={{ color: "#999999", fontSize: 12, fontWeight: 400, marginRight: 20 }}>
                                              {itens.estoque !== null ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativado</> : <>Desativado</>}
                                            </Typography>
                                            <FormControlLabel
                                              control={<MaterialUISwitch sx={{ m: 1 }} checked={itens.estoque !== null} onChange={() => handleHabilitaItem(itens)} />}
                                            />
                                          </Grid>
                                        </Grid>

                                      </>
                                    )

                                  ))}
                                </AccordionDetails>
                              </Accordion>
                              <div style={{ height: 16 }} />

                            </>
                          )
                        ))}
                      </>
                    }
                  </Grid>


                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
    </ThemeProvider >
  );
}

export default Inventario;