import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTabPanel, a11yProps, createNewTheme } from '../../functions/utils';
import { Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert } from '@mui/material';
import { DragIndicator, KeyboardArrowRight, PhoneOutlined, WhatsApp } from '@mui/icons-material';
import Cookies from 'js-cookie';
import axios from 'axios';

function Suporte({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tipoSuporte, setTipoSuporte] = useState(0);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");


  const handleSuporteChange = (event, newValue) => {
    setTipoSuporte(newValue);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleMensagem = (event) => {
    setMensagem(event.target.value);
  };

  const handleEmail = () => {
    if (!nome) {
      update("", `${Date.now()}___error___Por favor preencha seu nome.`)
      return
    }
    if (!mensagem) {
      update("", `${Date.now()}___error___Por favor preencha a mensagem com sua dúvida para nosso suporte entrar em contato.`)
      return
    }
    const post_url = "https://mely.online/api/suporte-email.php";
    const json = JSON.stringify({
      nome: nome,
      email: email,
      mensagem: mensagem
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response) {
          if (response.resposta === "success") {
            update("", `${Date.now()}___success___Sua solicitação de suporte foi enviada com sucesso.`)
            setMensagem("")
            setNome("")
            return
          }
        }
        update("", `${Date.now()}___error___Não foi possivel enviar a sua solicitação de suporte. Por favor verifique sua conexão e tente novamente.`)
      })
      .catch(error => update("", `${Date.now()}___error___Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.`));

  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          update("", `${Date.now()}___success___Número copiado com sucesso.`)
        })
        .catch(err => {
          update("", `${Date.now()}___error___Não foi possivel copiar o número`)
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        update("", `${Date.now()}___success___Número copiado com sucesso.`)
      } catch (err) {
        update("", `${Date.now()}___error___Não foi possivel copiar o número`)
      }
      document.body.removeChild(textArea);
    }
  }

  const handleCopy = (text) => {
    copyToClipboard(text)
  };

  const handleWhatsapp = (text) => {
    window.open(`https://wa.me/55${text}?text=Olá,%20preciso%20de%20ajuda%20com%20o%20mely!`, '_blank');
    copyToClipboard(text)
  };

  useEffect(() => {
    const email = Cookies.get('emEst');
    setEmail(email)
  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <div style={{ top: 75, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Alert
          variant="outlined"
          severity="info"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            color: "#464545", fontSize: 14,
            border: "none",
            background: "#ECF0F9"
          }}
        >
          <div>
            <b style={{ fontSize: 16 }}>Horário de funcionamento</b><br />
            Todos os dias das 07h00 às 19h00
          </div>
        </Alert>

        <div style={{ height: "22px" }} />

        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Suporte</Typography>
                <Typography style={{ fontWeight: "400", fontSize: 14, color: "#1B1B1B" }}>Entre em contato em um de nossos canais abaixo. </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <List>
                          <ListItem disablePadding style={{ backgroundColor: tipoSuporte === 0 ? "#F2F2F2" : "" }} onClick={(event) => handleSuporteChange(event, 0)}>
                            <ListItemButton>
                              <ListItemText primary={<Typography style={{ display: "flex" }}><DragIndicator />&nbsp;&nbsp;&nbsp;E-mail</Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding style={{ backgroundColor: tipoSuporte === 1 ? "#F2F2F2" : "" }} onClick={(event) => handleSuporteChange(event, 1)}>
                            <ListItemButton>
                              <ListItemText primary={<Typography style={{ display: "flex" }}><DragIndicator />&nbsp;&nbsp;&nbsp;Telefone</Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding style={{ backgroundColor: tipoSuporte === 2 ? "#F2F2F2" : "" }} onClick={(event) => handleSuporteChange(event, 2)}>
                            <ListItemButton>
                              <ListItemText primary={<Typography style={{ display: "flex" }}><DragIndicator />&nbsp;&nbsp;&nbsp;Whatsapp</Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          {/* <ListItem disablePadding style={{ backgroundColor: tipoSuporte === 3 ? "#F2F2F2" : "" }} onClick={(event) => handleSuporteChange(event, 3)}>
                            <ListItemButton>
                              <ListItemText primary={<Typography><DragIndicator />Chat online</Typography>} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItemButton>
                          </ListItem>
                          <Divider /> */}
                        </List>

                      </Grid>
                    </Grid>
                  </Grid>
                  {tipoSuporte === 0 &&
                    <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: isMobile ? 0 : 16, paddingTop: isMobile ? 16 : 0 }}>

                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Nome <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <div style={{ height: 4 }}></div>
                      <TextField
                        fullWidth
                        hiddenLabel
                        value={nome}
                        onChange={handleNome}
                        variant="outlined"
                        size="large"
                        placeholder="Digite aqui seu nome"
                        type="text"
                      />

                      <div style={{ height: 18 }} />

                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        E-mail <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <div style={{ height: 4 }}></div>
                      <TextField
                        fullWidth
                        hiddenLabel
                        value={email}
                        disabled
                        style={{ background: "#f2f2f2" }}
                        variant="outlined"
                        size="large"
                        placeholder="Digite aqui seu e-mail"
                        type="text"
                      />

                      <div style={{ height: 18 }} />


                      <Typography style={{ textAlign: "left", fontSize: "14px", fontWeight: "600" }}>
                        Mensagem <b style={{ color: "#A51D1D" }}>*</b>
                      </Typography>
                      <div style={{ height: 4 }}></div>
                      <TextField
                        fullWidth
                        hiddenLabel
                        value={mensagem}
                        onChange={handleMensagem}
                        variant="outlined"
                        size="large"
                        inputProps={{ maxLength: 140 }}
                        placeholder="Digite aqui sua mensagem"
                        type="text"
                        multiline
                        rows={4}
                      />

                      <div style={{ height: 18 }} />

                      <Button variant="contained" fullWidth size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} onClick={handleEmail}>
                        Enviar
                      </Button>

                    </Grid>
                  }

                  {tipoSuporte === 1 &&
                    <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: isMobile ? 0 : 16, paddingTop: 8 }}>
                      <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                        <CardHeader
                          title={<Typography style={{ display: "flex" }}><PhoneOutlined /> &nbsp;&nbsp; Telefone</Typography>}
                          style={{ borderBottom: "solid #DCDBDB 1px", background: "#F2F2F2" }}
                        />
                        <CardContent>
                          <Typography style={{ fontSize: 16 }}>
                            Entre em contato com um de nossos números abaixo
                          </Typography>

                          <Button onClick={() => handleCopy("11994247794")} variant="outlined" fullWidth={isMobile} size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} startIcon={<PhoneOutlined />}>
                            (11) 99424-7794
                          </Button>

                          <Button onClick={() => handleCopy("11989006387")} variant="outlined" fullWidth={isMobile} size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500", marginLeft: isMobile ? 0 : 16 }} startIcon={<PhoneOutlined />}>
                            (11) 98900-6387
                          </Button>

                        </CardContent>
                      </Card>
                    </Grid>
                  }

                  {tipoSuporte === 2 &&
                    <Grid item xs={12} sm={12} md={9} style={{ paddingLeft: 16, paddingTop: 8 }}>
                      <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                        <CardHeader
                          title={<Typography style={{ display: "flex" }}><WhatsApp /> &nbsp;&nbsp; Whatsapp</Typography>}
                          style={{ borderBottom: "solid #DCDBDB 1px", background: "#F2F2F2" }}
                        />
                        <CardContent>
                          <Typography style={{ fontSize: 16 }}>
                            Entre em contato diretamente pelo nosso Whatsapp
                          </Typography>

                          <Button onClick={() => handleWhatsapp("11994247794")} variant="outlined" size="large" style={{ textTransform: 'none', marginTop: 16, fontWeight: "500" }} startIcon={<WhatsApp />}>
                            (11) 99424-7794
                          </Button>

                        </CardContent>
                      </Card>
                    </Grid>
                  }

                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>

    </ThemeProvider>
  );
}

export default Suporte;